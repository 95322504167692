import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getWindowDimensions } from 'components/utils';
import iosGuide1FR from '../../staticFiles/iosInstallGuide/iosGuide1FR.png';
import iosGuide2FR from '../../staticFiles/iosInstallGuide/iosGuide2FR.png';
import iosGuide3FR from '../../staticFiles/iosInstallGuide/iosGuide3FR.png';
import iosGuide4FR from '../../staticFiles/iosInstallGuide/iosGuide4FR.png';
import iosGuide5FR from '../../staticFiles/iosInstallGuide/iosGuide5FR.png';
import androidGuide1FR from '../../staticFiles/androidInstallGuide/androidGuide1FR.png';
import androidGuide2FR from '../../staticFiles/androidInstallGuide/androidGuide2FR.png';
import androidGuide3FR from '../../staticFiles/androidInstallGuide/androidGuide3FR.png';
import androidGuide4FR from '../../staticFiles/androidInstallGuide/androidGuide4FR.png';
import androidGuide5FR from '../../staticFiles/androidInstallGuide/androidGuide5FR.png';

export default function InstallGuide(props) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h5" component="div" id="installguide">
        {t('install_guide.title')}
      </Typography>
      <Typography variant="subtitle1" component="div">
        {t('install_guide.subtitle')}
      </Typography>
      <Accordion
        sx={{ width: '100%' }}
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h6">
            {' '}
            {t('install_guide.ios_title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScreenAdaptedAccordionDetail os="iOS" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ width: '100%' }}
        expanded={expanded === 'panel2'}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography variant="h6">
            {t('install_guide.android_title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScreenAdaptedAccordionDetail os="Android" />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function ScreenAdaptedAccordionDetail(props) {
  const { os } = props;
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    getWindowDimensions().width < 600,
  );

  React.useEffect(() => {
    function handleResize() {
      setIsSmallScreen(getWindowDimensions().width < 600);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (isSmallScreen) {
    return <SmallScreenAccordionDetails os={os} />;
  }
  return <LargeScreenAccordionDetails os={os} />;
}

function SmallScreenAccordionDetails(props) {
  const { os } = props;
  const { t } = useTranslation();
  if (os === 'iOS') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Box>
          <TutoText text={t('install_guide.ios_guide_1')} />
          <TutoImage number={1} image={iosGuide1FR} />
        </Box>
        <Box>
          <TutoText text={t('install_guide.ios_guide_2')} />
          <TutoImage number={2} image={iosGuide2FR} />
        </Box>
        <Box>
          <TutoText text={t('install_guide.ios_guide_3')} />
          <TutoImage number={3} image={iosGuide3FR} />
        </Box>
        <Box>
          <TutoText text={t('install_guide.ios_guide_4')} />
          <TutoImage number={4} image={iosGuide4FR} />
        </Box>
        <Box>
          <TutoText text={t('install_guide.ios_guide_5')} />
          <TutoImage number={5} image={iosGuide5FR} />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Box>
        <TutoText text={t('install_guide.android_guide_1')} />
        <TutoImage number={1} image={androidGuide1FR} />
      </Box>
      <Box>
        <TutoText text={t('install_guide.android_guide_2')} />
        <TutoImage number={2} image={androidGuide2FR} />
      </Box>
      <Box>
        <TutoText text={t('install_guide.android_guide_3')} />
        <TutoImage number={3} image={androidGuide3FR} />
      </Box>
      <Box>
        <TutoText text={t('install_guide.android_guide_4')} />
        <TutoImage number={4} image={androidGuide4FR} />
      </Box>
      <Box>
        <TutoText text={t('install_guide.android_guide_5')} />
        <TutoImage number={5} image={androidGuide5FR} />
      </Box>
      <Box>
        <TutoText text={t('install_guide.android_guide_6')} />
      </Box>
    </Box>
  );
}

function LargeScreenAccordionDetails(props) {
  const { os } = props;
  const { t } = useTranslation();
  if (os === 'iOS') {
    return (
      <>
        <TutoText text={t('install_guide.ios_guide_1')} />
        <TutoText text={t('install_guide.ios_guide_2')} />
        <TutoText text={t('install_guide.ios_guide_3')} />
        <TutoText text={t('install_guide.ios_guide_4')} />
        <TutoText text={t('install_guide.ios_guide_5')} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <TutoImage number={1} image={iosGuide1FR} />
          <TutoImage number={2} image={iosGuide2FR} />
          <TutoImage number={3} image={iosGuide3FR} />
          <TutoImage number={4} image={iosGuide4FR} />
          <TutoImage number={5} image={iosGuide5FR} />
        </Box>
      </>
    );
  }
  return (
    <>
      <TutoText text={t('install_guide.android_guide_1')} />
      <TutoText text={t('install_guide.android_guide_2')} />
      <TutoText text={t('install_guide.android_guide_3')} />
      <TutoText text={t('install_guide.android_guide_4')} />
      <TutoText text={t('install_guide.android_guide_5')} />
      <TutoText text={t('install_guide.android_guide_6')} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        <TutoImage number={1} image={androidGuide1FR} />
        <TutoImage number={2} image={androidGuide2FR} />
        <TutoImage number={3} image={androidGuide3FR} />
        <TutoImage number={4} image={androidGuide4FR} />
        <TutoImage number={5} image={androidGuide5FR} />
      </Box>
    </>
  );
}

function TutoText(props) {
  const { text } = props;
  return (
    <>
      <Typography variant="body1" sx={{ textAlign: 'left' }}>
        &bull;
        {' '}
        {text}
      </Typography>
      <br />
    </>
  );
}

function TutoImage(props) {
  const { number, image } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography>{number}</Typography>
      <img
        src={image}
        style={{ width: 200, objectFit: 'cover' }}
        alt="ios guide 1"
      />
    </Box>
  );
}
