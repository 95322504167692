import React, {
  useState, useMemo, useRef, useCallback,
} from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import useGlobal from 'global-state/store';
import alcoholUnitRelatedsFrom from 'components/units/UnitsRelatedFrom';
import { httpsCallable } from 'firebase/functions';
import { useFunctions, useUser } from 'reactfire';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getAnalytics, logEvent } from 'firebase/analytics';
import BatchForm from './BatchForm';
import VolumeConversionMethodInfo from './VolumeConversionMethodInfo';

export default function BatchSelection() {
  const { t } = useTranslation();
  const location = useLocation();
  const [globalState, globalActions] = useGlobal();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [volumeConversionMethod] = useState(globalState.volumeConversionMethod);
  const [batchesToConvert, setbatchesToConvert] = useState(initBatches(location.state.batches));
  const [operation] = useState(location.state.operation);
  const [isSubmitting, setisSubmitting] = useState(false);
  const navigate = useNavigate();
  const [alcoholUnitRelateds] = useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
    ),
  );
  const [errorMessage, seterrorMessage] = useState('');
  const [errorSeverity, seterrorSeverity] = useState('error');
  const [openError, setOpenError] = useState(false);
  const formikRefs = useRef([]);

  const paResultItems = useMemo(() => Object.entries(operation.pureAlcoholResults)
    .map(([key, value]) => ({ ...value, name: key })), [operation.pureAlcoholResults]);

  const functions = useFunctions();
  functions.region = 'europe-west1';
  const volumeConversionBatchCall = httpsCallable(functions, 'volumeConversionBatchCallM2V2');

  const handleError = (message, newErrorSeverity = 'error') => {
    seterrorMessage(message);
    seterrorSeverity(newErrorSeverity);
    setOpenError(true);
  };

  function getSessionItem() {
    return sessionStorage.getItem('batchesTempAndTav');
  }

  function initBatches(batches) {
    const savedBatchesString = getSessionItem();
    if (savedBatchesString) {
      return JSON.parse(savedBatchesString);
    }
    const notEmptyBatches = batches.filter((batch) => batch.compartments.length > 0);
    return notEmptyBatches.map((batch) => {
      const batchVolume = batch.compartments
        .map((compartment) => Number(compartment.volume)).reduce((a, b) => a + b, 0);
      return {
        number: batch.number,
        color: batch.color,
        volume: batchVolume,
        compartmentIds: batch.compartments.map((compartment) => compartment.id),
        temperature: '',
        tav: '',
        tavTemperature: '20',
      };
    });
  }

  function hasEmptyValue(obj) {
    return Object.values(obj).some((value) => value === null || value === undefined || value === '');
  }

  const handleSubmitBatches = async () => {
    let allValid = true;

    await Promise.all(formikRefs.current.map(async (formik) => {
      await formik.submitForm();
      if (!formik.isValid || hasEmptyValue(formik.values)) {
        allValid = false;
      }
    }));

    if (!allValid) {
      globalActions.setSnackbarMessage({ message: t('forms.invalid'), severity: 'error' });
      return;
    }

    if (globalState.networkState === 'offline') {
      handleError(t('forms.need_network'));
      return;
    }

    const request = {
      alcoholUnit: alcoholUnitRelateds.alcoholUnit,
      temperatureUnit: alcoholUnitRelateds.tempUnit,
      volumeUnit: 'hL',
      batch: batchesToConvert,
      method: volumeConversionMethod,
      traditionalMethod: globalState.volumeConversionMethod === 'traditional',
      intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
    };
    try {
      setisSubmitting(true);
      sessionStorage.setItem('batchesTempAndTav', JSON.stringify(batchesToConvert));
      const response = await volumeConversionBatchCall(request);
      const batchResults = response.data;
      batchesToConvert.forEach((batch) => {
        batchResults[batch.number].color = batch.color;
        batchResults[batch.number].compartmentIds = batch.compartmentIds;
        batchResults[batch.number].requestedValues = {
          volumeUnit: request.volumeUnit,
          alcoholUnit: request.alcoholUnit,
          temperatureUnit: request.temperatureUnit,
          tav: batch.tav,
          tavTemperature: batch.tavTemperature,
          temperature: batch.temperature,
          volume: batch.volume,
        };
      });
      logEvent(analytics, 'volume_conversion_submit_batch', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });

      navigate('result', {
        state: {
          batchResults,
          operation,
        },
      });
    } catch (error) {
      if (error.message === 'INTERNAL') {
        handleError(t('unexpected_error'));
      } else {
        handleError(error.message);
      }
      logEvent(analytics, 'error_batch_volume_conversion', {
        user_uid: user?.uid,
        error_message: error.message,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    } finally {
      setisSubmitting(false);
    }
  };

  const handleChangeBatch = useCallback((batchNumber, values) => {
    const newBatches = [...batchesToConvert];
    const batch = newBatches.find((b) => b.number === batchNumber);
    batch.tav = values.measuredTav;
    batch.temperature = values.measuredTemp;
    batch.tavTemperature = values.measuredTAVTemp;
    setbatchesToConvert(newBatches);
  }, [batchesToConvert]);

  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Typography variant="h6" component="span">
        {t('volume_conversion.temp_and_tav_selection')}
      </Typography>
      {globalState.userPlan === 'test_premium'
              && (
              <Alert
                style={{ justifyContent: 'center', alignItems: 'center' }}
                severity="warning"
              >
                {t('comm.test_premium_volume_conversion')}
              </Alert>
              )}
      {batchesToConvert?.map((batch, index) => (
        <div key={batch.number}>
          <BatchForm
            batch={batch}
            alcoholUnitRelateds={alcoholUnitRelateds}
            handleChangeBatch={handleChangeBatch}
            paResultItems={paResultItems}
            pureAlcoholResults={operation.pureAlcoholResults}
            formikRef={(el) => { formikRefs.current[index] = el; }}
          />
        </div>
      ))}
      <LoadingButton
        sx={{
          maxWidth: 200, alignSelf: 'center',
        }}
        variant="contained"
        type="submit"
        size="large"
        loading={isSubmitting}
        disabled={isSubmitting}
        onClick={handleSubmitBatches}
      >
        {t('volume_conversion.convert')}
      </LoadingButton>
      <VolumeConversionMethodInfo />
      <Snackbar
        open={openError}
        style={{ marginTop: 200 }}
        autoHideDuration={15000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert style={{ padding: 50 }} severity={errorSeverity}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>

  );
}
