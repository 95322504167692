import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dynamicUnitName } from 'components/UnitDefinitionsAndMasks';

export default function SelectPAResult({
  formik, pureAlcoholResults, batchIndex,
}) {
  const { t } = useTranslation();
  const { setValues, submitForm } = formik;
  const items = useMemo(() => Object.entries(pureAlcoholResults)
    .map(([key, value]) => ({ ...value, name: key })), [pureAlcoholResults]);

  const NONE_ITEM = useMemo(() => ({ name: 'none', values: {} }), []);

  const [selectedItem, setSelectedItem] = useState(batchIndex >= items.length ? NONE_ITEM : items[batchIndex]);

  const selectItem = useCallback(async (item) => {
    if (item.name === 'none') {
      await setValues({
        measuredTemp: '',
        measuredTAVTemp: '',
        measuredTav: '',
      });
    } else {
      await setValues({
        measuredTemp: item.values.volumeTemperature,
        measuredTAVTemp: item.values.tavTemp,
        measuredTav: item.values.measuredTav,
      });
    }
    submitForm();
  }, [setValues, submitForm]);

  return (
    <FormControl>
      <InputLabel id="prgms-label">{t('volume_conversion.select_pa_conv')}</InputLabel>
      <Select
        sx={{ minWidth: 150 }}
        labelId="pa-res-label"
        id="paResultsSelect"
        defaultValue=""
        value={selectedItem.name}
        renderValue={(name) => {
          const matchedItem = items.find((item) => item.name === name) || NONE_ITEM;
          return <ItemRenderValue item={matchedItem} />;
        }}
        onChange={(e) => {
          const itemName = e.target.value;
          const matchedItem = items.find((item) => item.name === itemName) || NONE_ITEM;
          selectItem(matchedItem);
          setSelectedItem(matchedItem);
        }}
        input={<OutlinedInput label={t('volume_conversion.select_pa_conv')} />}
      >
        <MenuItem
          key={NONE_ITEM.name}
          value={NONE_ITEM.name}
        >
          <em>{t('none')}</em>
        </MenuItem>
        {items.map((res, index) => (
          <MenuItem
            key={res.name}
            value={res.name}
          >
            <MyMenuItem results={res} index={index} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function ItemRenderValue({ item }) {
  const { t } = useTranslation();
  const { values } = item;
  if (item.name === 'none') {
    return (
      <Typography variant="subtitle1">
        {t('none')}
      </Typography>
    );
  }
  return (
    <Typography variant="subtitle1">
      {`${values.volumeValue} ${dynamicUnitName(values.volumeUnit)} ${t('alco_results.pure_alcohol_simple')}`}
    </Typography>
  );
}

function MyMenuItem({ results, index }) {
  const { t } = useTranslation();
  if (results.name === 'none') {
    return (
      <Typography variant="subtitle1">
        {t('none')}
      </Typography>
    );
  }
  const { volumeAtTemp } = results.paVolConv;
  const { values } = results;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        px: 2,
        py: 1,
        borderRadius: 1,
        backgroundColor: index % 2 === 0 ? '#F2F5F8' : 'white',
      }}
    >

      <Typography variant="caption">
        {t('alco_results.pure_alcohol_at', {
          referenceTempValue: 20,
          referenceTempUnit: dynamicUnitName('celsius'),
        })}
      </Typography>
      <Typography variant="caption">
        {`${values.volumeValue} ${dynamicUnitName(values.volumeUnit)}`}
      </Typography>

      <Typography variant="caption" sx={{ mt: 1 }}>
        {t('alco_results.volume_at', {
          referenceTempValue: volumeAtTemp.temperature.value,
          referenceTempUnit: dynamicUnitName(volumeAtTemp.temperature.unit),
        })}
      </Typography>
      <Typography variant="caption">
        {`${volumeAtTemp.value} ${dynamicUnitName(volumeAtTemp.unit)}`}
      </Typography>
    </Box>
  );
}
