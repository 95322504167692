import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useUser } from 'reactfire';
import Button from '@mui/material/Button';
import { getAuth, signOut } from 'firebase/auth';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  Link as MUILink,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { isUserAdmin } from 'components/utils';
import InstallGuide from 'components/account/InstallGuide';
import useGlobal from 'global-state/store';
import CalculMethod from './CalculMethod';

export default function MyAccount(props) {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const auth = getAuth();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();

  const [isAdmin, setIsAdmin] = React.useState(undefined);

  React.useEffect(() => {
    async function getCustomClain() {
      const isuseradmin = await isUserAdmin(user);
      if (isuseradmin) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }

    if (isAdmin === undefined) {
      getCustomClain();
    }
  }, [user, isAdmin]);

  function adminOrOperator() {
    if (isAdmin === true) {
      return 'Admin';
    }
    if (isAdmin === false) {
      return t('admin.operator');
    }
    return '_';
  }

  function email() {
    if (isAdmin === undefined) {
      return '_';
    }
    return user.email;
  }

  const logout = () => {
    signOut(auth).then(() => {
      globalActions.setUserStatus('initial');
      navigate('/');
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: '75%',
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 5,
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 1,
            mb: 3,
          }}
        >
          <b>
            {adminOrOperator()}
            {': '}
          </b>
          {email()}
        </Box>

        <Button
          variant="outlined"
          color="inherit"
          sx={{ color: 'red' }}
          size="large"
          onClick={logout}
          startIcon={<ExitToApp />}
        >
          {t('user_card.quit')}
        </Button>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography>{t('welcome.contact_message')}</Typography>
          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Box>
      </Paper>
      <CalculMethod />
      <InstallGuide />
    </Box>
  );
}
