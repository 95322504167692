import * as React from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useFirestore, useUser, useFunctions } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import { useLocation } from 'react-router-dom';
import TankCreationForm from './TankCreationForm';
import {
  getTankTransporterNames,
} from './firestoreRequests';

export default function TankCreation() {
  const db = useFirestore();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [loadingStatus, setloadingStatus] = React.useState('not loaded');
  const [transporters, settransporters] = React.useState({});
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [globalState] = useGlobal();
  const location = useLocation();
  const [update] = React.useState(location.state?.update || false);

  React.useEffect(() => {
    async function loadTransporters() {
      setloadingStatus('loading');
      const newTransporters = await getTankTransporterNames(
        db,
        `organizations/${globalState.activeOrganization}/apps/digitank-transporter`,
      );
      settransporters([...newTransporters]);
      setloadingStatus('loaded');
      logEvent(analytics, 'loading_transporters_in_tank_creation', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    }

    if (user?.uid !== undefined && globalState.activeOrganization !== '' && loadingStatus === 'not loaded') {
      try {
        setloadingStatus('loading');
        loadTransporters();
      } catch (e) {
        logEvent(analytics, 'error_loading_transporters_and_tanks', {
          user_uid: user?.uid,
          error_message: e.message,
          appName: 'Digitank, Tanker Trucks',
          organization: globalState.activeOrganization,
        });
      }
    }
  }, [db, user?.uid, loadingStatus, analytics, globalState.activeOrganization]);

  if (loadingStatus === 'loaded') {
    return <TankCreationForm transporters={transporters} update={update} />;
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
