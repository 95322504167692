import React, { useState, useEffect } from 'react';
import { Paper, CircularProgress, Box } from '@mui/material';
import {
  collection, doc, getDoc, setDoc,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import ReportForm from './ReportForm';

function CertificatesMonitoring() {
  const { t } = useTranslation();
  const db = useFirestore();
  const [loadingStatus, setLoadingStatus] = useState('initial');
  const [expirationInitialValues, setExpirationInitialValues] = useState(null);
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    const fetchData = async () => {
      const dataRef = doc(
        db,
        'organizations',
        globalState.activeOrganization,
        'apps',
        'digitank-tanker-trucks',
        'data',
        'monitoring',
      );
      const dataSnap = await getDoc(dataRef);

      if (dataSnap.exists()) {
        setExpirationInitialValues(
          {
            report: dataSnap.data().reportCertificateExpiration || { active: false, emails: [] },
          },
        );
      } else {
        setExpirationInitialValues({ report: { active: false, emails: [] } });
      }
      setLoadingStatus('done');
    };

    if (loadingStatus === 'initial' && !['initial', 'loading'].includes(globalState.userStatus)) {
      setLoadingStatus('loading');
      fetchData();
    }
  }, [db, globalState.activeOrganization, globalState.userStatus, loadingStatus]);

  const expirationHandleSubmit = async (values) => {
    const dataCollectionRef = collection(
      db,
      'organizations',
      globalState.activeOrganization,
      'apps',
      'digitank-tanker-trucks',
      'data',
    );
    const monitoringDocRef = doc(dataCollectionRef, 'monitoring');
    await setDoc(monitoringDocRef, { reportCertificateExpiration: values.report }, { merge: true });
    globalActions.setSnackbarMessage({ message: t('success'), severity: 'success' });
  };

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Paper sx={{ p: 3, maxWidth: 450, minWidth: 300 }}>
        {loadingStatus !== 'done' ? (
          <CircularProgress />
        ) : (
          <ReportForm
            title={t('admin.report_certif_title')}
            info={t('admin.report_certif_info')}
            initialValues={expirationInitialValues}
            onSubmit={expirationHandleSubmit}
          />
        )}
      </Paper>
    </Box>
  );
}

export default CertificatesMonitoring;
