import {
  query, collection, getDocs, doc, getDoc,
} from 'firebase/firestore';

export async function getTransportersOfClient(db, ttAppPath, callback) {
  const transportersQuery = query(collection(db, ttAppPath, 'transporters'));
  const querySnapshot = await getDocs(transportersQuery);
  const transporterDocs = querySnapshot.docs;
  const newTransporters = transporterDocs.map((transporterDoc) => transporterDoc.id);
  callback(newTransporters);
}

async function getTanksOfTransporter(firestore, path) {
  const tanksQuery = query(
    collection(firestore, `${path}/tanks`),

  );
  const querySnapshot = await getDocs(tanksQuery);
  return querySnapshot.docs;
}

export async function getTankTransporterNames(firestore, path) {
  const transporters = new Set();

  const tanksOfTransporterDocs = await getTanksOfTransporter(firestore, path);
  for (const tankDoc of tanksOfTransporterDocs) {
    const data = tankDoc.data();
    transporters.add(data.transporterName);
  }
  return transporters;
}

export async function tankAlreadyExists(db, ttAppPath, transporterId, tankId) {
  const docRef = doc(db, ttAppPath, 'transporters', transporterId, 'tanks', tankId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists();
}
