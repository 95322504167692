import * as yup from 'yup';
import {
  dynamicUnitName,
  dynamicUnitRestrictions,
} from './UnitDefinitionsAndMasks';

export default function ValidationSchema(t, alcoholUnitRelateds) {
  const validationPhrases = {
    tavValidationPhrase: t('validations.tav_validation', {
      tavMin: alcoholUnitRelateds.tavMin,
      tavMax: alcoholUnitRelateds.tavMax,
      alcoholUnit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit),
    }),
    tempValidationPhrase: t('validations.temperature_validation', {
      tempMin: alcoholUnitRelateds.tempMin,
      tempMax: alcoholUnitRelateds.tempMax,
      tempUnit: dynamicUnitName(alcoholUnitRelateds.tempUnit),
    }),
  };

  function temperationValidationPhrase(tempUnit) {
    return t('validations.temperature_validation', {
      tempMin: dynamicUnitRestrictions('temp_min_', 'TAV', tempUnit),
      tempMax: dynamicUnitRestrictions('temp_max_', 'TAV', tempUnit),
      tempUnit: dynamicUnitName(tempUnit),
    });
  }

  return yup.object().shape({
    temperatureUnit: yup.string().required(),
    measuredTav: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tavValidationPhrase)
      .required(validationPhrases.tavValidationPhrase)
      .min(alcoholUnitRelateds.tavMin, validationPhrases.tavValidationPhrase)
      .max(alcoholUnitRelateds.tavMax, validationPhrases.tavValidationPhrase),
    tavTemp: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
    volumeTemperature: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(validationPhrases.tempValidationPhrase)
      .required(validationPhrases.tempValidationPhrase)
      .min(alcoholUnitRelateds.tempMin, validationPhrases.tempValidationPhrase)
      .max(alcoholUnitRelateds.tempMax, validationPhrases.tempValidationPhrase),
    volumeUnit: yup.string().required(),
    volumeValue: yup
      .number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .typeError(t('validations.volume_validation'))
      .required(t('validations.volume_validation'))
      .min(0, t('validations.volume_validation'))
      .max(99999999, t('validations.volume_validation')),
  });
}
