import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useFirestore, useUser } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { storeClearOperationCpts } from 'components/operation/compartmentStore';
import useGlobal from 'global-state/store';
import ReplayIcon from '@mui/icons-material/Replay';
import OrganizationsSelect from 'components/organizations/OrganizationsSelect';
import useMenu from 'menu-actions/useMenu';
import NotYetSetup from './NotYetSetup';
import getTransporterIdsAndTankIds from './getTransporterIdsAndTankIds';
import LastOperationsDialog from './LastOperationsDialog';

export default function TankSelection() {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { status, data: user } = useUser();
  const [transporter, setTransporter] = useState('');
  const [tank, setTank] = useState('');
  const [transportersAndTanks, settransportersAndTanks] = useState({});
  const db = useFirestore();
  const [openError, setOpenError] = useState(false);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const [globalState, globalActions] = useGlobal();
  const [loadingState, setloadingState] = useState('notLoaded');
  const { dispatchMenuActions } = useMenu();
  const [lastOperationsOpen, setLastOperationsOpen] = useState(false);

  useEffect(() => {
    const openLastOperations = {
      id: 'open_last_operations',
      name: t('tank_selection.open_last_operations'),
      callback: () => setLastOperationsOpen(true),
    };

    dispatchMenuActions({ type: 'clear' });
    dispatchMenuActions({
      type: 'add',
      actions: [openLastOperations],
    });
  }, [dispatchMenuActions, t]);

  useEffect(() => {
    async function getTransportersOfUser() {
      try {
        const newTransportersAndTanks = await getTransporterIdsAndTankIds(
          db,
          globalState,
          settransportersAndTanks,
          setloadingState,
          setTransporter,
          setTank,
        );
        globalActions.setTransporterNames(Object.keys(newTransportersAndTanks));
        logEvent(analytics, 'loading_transporters_and_tanks', {
          user_uid: user?.uid,
          appName: 'Digitank, Tanker Trucks',
          organization: globalState.activeOrganization,
        });
      } catch (e) {
        setloadingState('error');
        if (globalState.networkState !== 'offline') {
          logEvent(analytics, 'error_loading_transporters_and_tanks', {
            user_uid: user?.uid,
            error_message: e.message,
            appName: 'Digitank, Tanker Trucks',
            organization: globalState.activeOrganization,
          });
          setOpenError(true);
        }
      }
    }

    if (globalState.userStatus === 'notYetSetup') {
      setloadingState('userNotYetSetup');
    }

    if (user?.uid !== undefined && loadingState === 'notLoaded' && globalState.userStatus === 'setupDone') {
      setloadingState('loading');
      storeClearOperationCpts(sessionStorage);
      getTransportersOfUser();
    }
  }, [db, user?.uid, analytics, globalActions, globalState, loadingState]);

  const handleChangeTransporter = (event) => {
    setTransporter(event.target.value);
    setTank(transportersAndTanks[event.target.value][0] ?? '');
  };

  const handleChangeTank = (event) => {
    setTank(event.target.value);
  };

  function saveTransporterAnTank() {
    localStorage.setItem(
      'saved-transporter-and-tank',
      JSON.stringify({
        transporter,
        tank: tank.id,
      }),
    );
  }

  const handleGoToOperation = () => {
    saveTransporterAnTank();
    navigate('operation', {
      state: {
        tankPath: tank.path,
      },
    });
  };

  const transporterDisplay = (organizationAndTransporter) => {
    const orgAndTransporter = organizationAndTransporter.split('::');
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Typography>
          {orgAndTransporter[1]}
        </Typography>
        <Typography variant="caption">
          {orgAndTransporter[0]}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: 5,
    }}
    >
      <OrganizationsSelect changeCallback={() => setloadingState('notLoaded')} />
      <Paper
        elevation={0}
        sx={{
          ml: 1,
          mr: 1,
          pt: 3,
          pb: 3,
          pl: 1,
          pr: 1,
          width: '100%',
          maxWidth: 800,
          alignSelf: 'center',
        }}
      >
        {loadingState === 'loaded' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Box sx={{ flexDirection: 'column', m: 5 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{ mb: 2 }}
              >
                {t('tank_selection.select_transporter')}
              </Typography>
              <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                <InputLabel id="transporter-select-label">
                  {t('tank_selection.transporter')}
                </InputLabel>
                <Select
                  labelId="transporter-select-label"
                  id="transporter-select"
                  value={transporter}
                  label={t('tank_selection.transporter')}
                  onChange={handleChangeTransporter}
                >
                  {Object.keys(transportersAndTanks).map((u, i) => (
                    <MenuItem key={u} value={u}>
                      {transporterDisplay(u)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ flexDirection: 'column', m: 5 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{ mb: 2 }}
              >
                {t('tank_selection.select_tank')}
              </Typography>
              <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                <InputLabel id="tank-select-label">
                  {t('tank_selection.tank')}
                </InputLabel>
                <Select
                  labelId="tank-select-label"
                  id="tank-select"
                  value={tank}
                  label={t('tank_selection.tank')}
                  onChange={handleChangeTank}
                >
                  {transportersAndTanks[transporter].map((u, i) => (
                    <MenuItem key={u.id} value={u}>
                      {u.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Button
            variant="contained"
            size="large"
            disabled={tank === ''}
            sx={{ mt: 5 }}
            onClick={handleGoToOperation}
            endIcon={<ArrowForward />}
          >
            {t('tank_selection.go_to_operation')}
          </Button>
        </Box>
        )}
        {(loadingState === 'notLoaded'
      || loadingState === 'loading') && <CircularProgress />}
        {loadingState === 'userNotYetSetup' && (
        <NotYetSetup />
        )}
        {loadingState === 'error' && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          endIcon={<ReplayIcon />}
        >
          {t('reload')}
        </Button>
        )}
        <Snackbar
          open={openError}
          onClose={() => setOpenError(false)}
          style={{ marginTop: 200 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert style={{ padding: 50 }} severity="error">
            <AlertTitle>{t('tank_selection.cant_load_title')}</AlertTitle>
            {t('tank_selection.cant_load')}
          </Alert>
        </Snackbar>
      </Paper>
      <LastOperationsDialog setOpen={setLastOperationsOpen} open={lastOperationsOpen} organizationName />
    </Box>
  );
}
