/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
// #TODO need to take time to remove the rules above and fix
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CompartmentForm from './CompartmentForm';
import { nominalVolumeFromCompartment } from '../utils';

export default function LargeScreenCompartments(props) {
  const { t } = useTranslation();
  const { compartments, forms } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 3,
        width: '100%',
        maxWidth: 1000,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: 2,
        }}
      >
        <Box sx={{ width: '25%' }}>
          <b>{t('operation.compartments')}</b>
        </Box>
        <Box sx={{ width: '25%' }}>
          <b>{t('operation.nominal_volume')}</b>
        </Box>
        <Box sx={{ width: '25%' }}>
          <b>{t('operation.height')}</b>
        </Box>
        <Box sx={{ width: '25%' }}>
          <b>{t('operation.volume')}</b>
        </Box>
      </Box>

      {compartments.map((cpt) => (
        <LargeScreenCompartment
          key={cpt.id}
          compartment={cpt}
          form={forms.find((form) => form.number === cpt.id)}
          {...props}
        />
      ))}
    </Box>
  );
}

function LargeScreenCompartment(props) {
  const { t } = useTranslation();
  const nominalVolume = nominalVolumeFromCompartment(props.compartment, t);
  return (
    <CompartmentForm
      layoutStyle={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        width: '100%',
        gap: 1,
        backgroundColor: props.bg === undefined ? '#fff' : props.bg,
        padding: 10,
      }}
      inputStyle={{ flexBasis: '25%' }}
      headers={(
        <>
          <Box sx={{ width: '25%', alignSelf: 'center' }}>
            {props.compartment.id}
          </Box>
          <Box sx={{ width: '25%', alignSelf: 'center' }}>{nominalVolume}</Box>
        </>
      )}
      {...props}
    />
  );
}
