import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { dynamicUnitName } from 'components/UnitDefinitionsAndMasks';
import DeleteIcon from '@mui/icons-material/Delete';
import useGlobal from 'global-state/store';
import AddIcon from '@mui/icons-material/Add';
import { useLocation } from 'react-router-dom';

export default function PureAlcoholResults({
  pureAlcoholResults,
  setPureAlcoholResults,
  handleGoToPAVolumeConversion,
}) {
  const [globalState] = useGlobal();
  const location = useLocation();

  if (!pureAlcoholResults) {
    return null;
  }

  const handleDelete = (keyToDelete) => {
    const newPureAlcoholResults = Object.keys(pureAlcoholResults)
      .reduce((res, key) => {
        if (key !== keyToDelete) {
          res[key] = pureAlcoholResults[key];
        }
        return res;
      }, {});
    setPureAlcoholResults(newPureAlcoholResults);
  };

  const displayButton = globalState.pureAlcoConversionOperationButton
    || Object.entries(pureAlcoholResults).length > 0;

  const displayResults = location.state.paResults
    && Object.entries(location.state.paResults).length > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 1,
        justifyContent: 'center',
        alignItems: 'stretch',
      }}
    >
      {displayResults && Object.entries(pureAlcoholResults).map((res) => (
        <ResBox
          key={res[0]}
          valuesAndResults={res[1]}
          onDelete={() => handleDelete(res[0])}
        />
      ))}
      {displayButton && (
        <PAConvButton handleGoToPAVolumeConversion={handleGoToPAVolumeConversion} />
      )}
    </Box>
  );
}

function ResBox({ valuesAndResults, onDelete }) {
  const { t } = useTranslation();
  const { volumeAtTemp } = valuesAndResults.paVolConv;
  const { ABVAt20c } = valuesAndResults.tavConv;
  const { values } = valuesAndResults;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        px: 0.5,
        py: 0.5,
        borderRadius: 1,
      }}
    >

      <Typography variant="caption">
        {t('alco_results.pure_alcohol_at', {
          referenceTempValue: 20,
          referenceTempUnit: dynamicUnitName('celsius'),
        })}
        {' : '}
        {`${values.volumeValue} ${dynamicUnitName(values.volumeUnit)}`}
      </Typography>
      <Typography variant="caption">
        {t('alco_results.tav_at', {
          referenceTempValue: 20,
          referenceTempUnit: dynamicUnitName('celsius'),
        })}
        {' : '}
        {`${ABVAt20c.value} ${dynamicUnitName(ABVAt20c.unit)}`}
      </Typography>
      <Typography variant="caption">
        {t('alco_results.volume_at', {
          referenceTempValue: volumeAtTemp.temperature.value,
          referenceTempUnit: dynamicUnitName(volumeAtTemp.temperature.unit),
        })}
        {' : '}
        <b>
          {`${volumeAtTemp.value} ${dynamicUnitName(volumeAtTemp.unit)}`}
        </b>
      </Typography>

      <IconButton size="small" onClick={onDelete} sx={{ m: 0, p: 0 }}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

function PAConvButton({ handleGoToPAVolumeConversion }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        px: 1,
        py: 1,
        borderRadius: 1,
        cursor: 'pointer',
      }}
      onClick={handleGoToPAVolumeConversion}
    >
      <AddIcon sx={{ fontSize: 40 }} />
      <Typography variant="caption">
        {t('operation.to_pa_vol_conversion')}
      </Typography>

    </Box>
  );
}
