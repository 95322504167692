import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import moment from 'moment';
import { replaceComma } from 'components/utils';
import useGlobal from 'global-state/store';
import modelInfoFr from '../../../../staticFiles/modele-info.csv';

export default function TankInfoDropZone(props) {
  const [, globalActions] = useGlobal();
  const {
    formik, errorHandler, aborthandler, transporters, acceptedHeightUnit, acceptedVolumeUnit, update = false,
  } = props;
  const { t } = useTranslation();

  function getExample() {
    return modelInfoFr;
  }

  const callback = (acceptedFiles, setfileName) => {
    // #TODO make sure these are processed before you can submit again
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      const parsedCsv = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
      const errorMessage = t('tank_creation.can_not_parse_csv');
      if (parsedCsv.errors.length > 0) {
        globalActions.setSnackbarMessage({
          message: `${errorMessage}\n${parsedCsv.errors[0].message}`,
          severity: 'error',
        });
      } else {
        try {
          setFormikValues(modelFromData(parsedCsv.data[0]));
        } catch (error) {
          globalActions.setSnackbarMessage({ message: errorMessage, severity: 'error' });
        }
        setfileName(file.path);
      }
    };

    reader.readAsText(file);
  };

  function modelFromData(data) {
    return {
      transporter: data['Propriétaire'] || data.proprietaire,
      certificateNumber: data['N° certificat'] || data.certificat,
      tankId: data["N° d'immatriculation"] || data.identification,
      provider: data['Prestataire de jaugeage'] || data.prestataire_de_jaugeage,
      heightUnit: data['Unités de hauteur'] || data.unites_de_hauteur,
      volumeUnit: data['Unités de volume'] || data.unites_de_volume,
      capacity: replaceComma(data['Capacité citerne']) || replaceComma(data.capacite_citerne),
      expirationDate: moment(
        data['Date limite de validité'] || data.date_limite_de_validite,
        'DD/MM/YYYY',
      ).toDate(),
      gaugeDate: moment(data['Date du certificat'] || data.date_du_certificat, 'DD/MM/YYYY').toDate(),
      nominalVolumesFromInfo: nominalVolumesFromFileInfo(data),
    };
  }

  function setFormikValues(model) {
    if (update) {
      if (model.transporter !== formik.values.transporter) {
        globalActions.setSnackbarMessage({
          message: t('tank_creation.not_same_transporter'),
          severity: 'error',
        });
      }
      if (model.tankId !== formik.values.tankId) {
        globalActions.setSnackbarMessage({
          message: t('tank_creation.not_same_tank_id'),
          severity: 'error',
        });
      }
    } else {
      formik.setFieldValue('transporter', existingsOrEmptyString(model.transporter.toString()));
      formik.setFieldValue('tankId', model.tankId.toString());
    }

    formik.setFieldValue('certificateNumber', model.certificateNumber.toString());
    formik.setFieldValue('provider', model.provider.toString());
    formik.setFieldValue('heightUnit', unitOrEmptyString(acceptedHeightUnit, model.heightUnit));
    formik.setFieldValue('volumeUnit', unitOrEmptyString(acceptedVolumeUnit, model.volumeUnit));
    formik.setFieldValue('capacity', model.capacity);
    formik.setFieldValue('expirationDate', model.expirationDate);
    formik.setFieldValue('gaugeDate', model.gaugeDate);
    formik.setFieldValue(
      'nominalVolumesFromInfo',
      model.nominalVolumesFromInfo,
    );
  }

  function existingsOrEmptyString(newTransporter) {
    if (!transporters.includes(newTransporter)) {
      return '';
    }
    return newTransporter;
  }

  function unitOrEmptyString(availableUnits, newUnit) {
    if (!availableUnits.includes(newUnit)) {
      return '';
    }
    return newUnit;
  }

  function nominalVolumesFromFileInfo(data) {
    const compartmentVolumeHeaders = Object.keys(data).filter(
      (header) => header.includes('Volume nominal: ') || header.includes('volume_nominal_'),
    );
    const compartmentNamesAndValues = compartmentVolumeHeaders.reduce(
      (all, header) => ({
        ...all,
        [header.split(/Volume nominal: |volume_nominal_/)[1]]: data[header],
      }),
      {},
    );
    return compartmentNamesAndValues;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <FileDropzone
        text={t('tank_creation.upload_csv_text')}
        callback={callback}
        accept={{ 'text/csv': ['.csv'] }}
      />
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
    </Box>
  );
}
