import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AccountBox from '@mui/icons-material/AccountBox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useGlobal from 'global-state/store';
import useMenu from 'menu-actions/useMenu';
import {
  Link as MUILink,
} from '@mui/material';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import LanguageSelect from './LanguageSelect';
import { inAdminPage } from './utils';
import logofr from '../staticFiles/logo-tt-fr.svg';
import logoen from '../staticFiles/logo-tt-en.svg';

export default function TopButtons() {
  const { menuActions } = useMenu();
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [globalState] = useGlobal();

  function displayAdminButton() {
    return globalState.roles.includes('admin') && !inAdminPage(location) && displayAccountButton();
  }

  function displayOperatorButton() {
    return globalState.roles.includes('admin') && inAdminPage(location) && displayAccountButton();
  }

  function displayAccountButton() {
    return !location.pathname.includes('account')
      && !location.pathname.includes('pdfViewer')
      && !location.pathname.includes('volumeConversion')
      && !location.pathname.includes('signin')
      && !location.pathname.includes('cgu')
      && !location.pathname.includes('contact');
  }

  function displayMenu() {
    return location.pathname === '/'
    || (location.pathname.includes('operation') && !location.pathname.includes('admin'))
    || location.pathname.includes('pdfViewer');
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 1,
      }}
    >
      <LogoOrReturn />
      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {displayAdminButton() && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            component={Link}
            to="admin"
          >
            Admin
          </Button>
        )}
        {displayOperatorButton() && (
          <Button
            size="small"
            variant="contained"
            color="info"
            component={Link}
            to="/"
          >
            {t('admin.operator')}
          </Button>
        )}
        {displayAccountButton() && (
          <IconButton
            aria-label="account"
            component={Link}
            to="account"
            sx={{ p: 0, fontSize: 37 }}
            color="primary"
          >
            <AccountBox fontSize="inherit" />
          </IconButton>
        )}
        {displayMenu() && (
          <IconButton
            sx={{ m: 0 }}
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <MyMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          menuOpen={menuOpen}
          actions={menuActions}
        />
        {!displayMenu() && <LanguageSelect />}
      </Box>
    </Box>
  );
}

function LogoOrReturn() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { returnAction, setreturnAction } = useMenu();

  const logo = () => (t('locale') === 'fr' ? logofr : logoen);

  useEffect(() => {
    setreturnAction(() => () => navigate(-1));
  }, [location, navigate, setreturnAction]);

  if (['/signin'].includes(location.pathname)) {
    return (
      <img
        src={logo()}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }

  if (['/'].includes(location.pathname)) {
    return (
      <img
        src={logo()}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }
  return (
    <IconButton size="large" onClick={returnAction}>
      <ArrowBack />
    </IconButton>
  );
}

function MyMenu(props) {
  const {
    anchorEl, handleMenuClose, menuOpen, actions,
  } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
    >
      {Object.entries(actions).map((action) => (
        <MenuItem key={action[0]} onClick={() => { action[1].callback(); handleMenuClose(); }}>
          {action[1].name}
        </MenuItem>
      ))}
      {location.pathname === '/' && (
        <MenuItem
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 2,
          }}
          onClick={() => handleRedirect('https://cc.digitank.labox-apps.com')}
        >
          <WaterDropIcon />
          <MUILink
            color="primary"
            sx={{ fontWeight: 'bold', fontSize: 12 }}
          >
            {t('app_name.digitank-cleaning-certificates')}
          </MUILink>
        </MenuItem>
      )}
      <Box sx={{ ml: 2, mt: 1 }}>
        <LanguageSelect />
      </Box>
    </Menu>
  );
}
