import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import useGlobal from 'global-state/store';

export default function CertificateDetail({
  open, setOpen, certificate: { certificate, tank }, certificateDeleteCallback,
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  if (!certificate) {
    return (
      <>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {`${t('tank_selection.certificate')} : ${certificate.id}`}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3, pb: 0,
      }}
      >
        <CertificateContent certificate={certificate} tank={tank} />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1 }}>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CertificateContent(props) {
  const { t } = useTranslation();
  const { certificate, tank } = props;
  const navigate = useNavigate();
  const [globalState] = useGlobal();

  const handleGoToPDFViewer = () => {
    // eslint-disable-next-line max-len
    navigate(`/pdfViewer?filePath=${encodeURIComponent(`gs://labox-ws.appspot.com/organizations/${globalState.activeOrganization}/apps/digitank-transporter/tanks/${tank.id}/gaugeCertificates/${certificate.id}.pdf`)}`);
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography variant="body2">
            {`${t('tank_selection.tank')} : ${tank.id}`}
          </Typography>

        </Box>
        <Typography variant="body2">
          {`${t('tank_selection.valid_until')} : ${new Date(certificate.expirationDate).toLocaleDateString()}`}
        </Typography>
      </Box>
      <Button
        onClick={handleGoToPDFViewer}
        size="small"
        variant="contained"
        color="primary"
        startIcon={<PictureAsPdfIcon />}
      >
        {t('storage.certificate_pdf')}
      </Button>
    </Box>
  );
}
