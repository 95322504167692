import React from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

export default function CertificateListItem({
  index, handleOpen, certificate, alternateBackground, selectCallback,
}) {
  const background = alternateBackground ? 'white' : '#edeff2';

  return (
    <ListItem
      key={index}
      component={Box}
      sx={{ background, m: 0, p: 0 }}
    >
      <ListItemButton sx={{ p: 2 }} onClick={() => handleOpen(certificate)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            flexWrap: 'wrap',
            rowGap: 1,
          }}
        >
          <Typography>
            {certificate.id}
          </Typography>
          <Typography>
            {new Date(certificate.expirationDate).toLocaleDateString()}
          </Typography>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
