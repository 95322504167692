import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Truck from '@mui/icons-material/LocalShipping';
import Alert from '@mui/material/Alert';
import ValidationForm from './ValidationForm';

export default function TankCard(props) {
  const { t } = useTranslation();
  const {
    setLoadingState, tankPath, tankNumber, certificate, transporterName, validation, verify, dispatchMenuActions,
  } = props;
  const certificateDate = new Date(certificate.expirationDate);
  const certificateExpired = certificateDate < new Date();
  const certificateExpiresSoon = !certificateExpired
    && new Date(new Date(certificateDate).setMonth(certificateDate.getMonth() - 1)) < new Date();
  const { hasBeenChecked, isValid } = validation;
  const dateColor = () => {
    if (certificateExpired) {
      return 'red';
    } if (certificateExpiresSoon) {
      return 'orange';
    }
    return 'black';
  };
  const [displayVerify, setdisplayVerify] = React.useState(!hasBeenChecked);

  React.useEffect(() => {
    const verifyCertificateAction = {
      name: t('tank_selection.verify_procedure'),
      callback: () => setdisplayVerify(true),
    };
    dispatchMenuActions({ type: 'add', actions: [verifyCertificateAction] });
  }, [dispatchMenuActions, t]);

  const verifyAndDisplay = (newValidation) => {
    setdisplayVerify(false);
    verify(newValidation);
  };

  return (
    <Paper sx={{ minWidth: 275, p: 3 }} elevation={0}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box sx={{ flexDirection: 'column' }}>
          <Truck sx={{ fontSize: 40, color: '#437A89' }} />
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ color: '#437A89' }}
          >
            {transporterName}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="body2" component="span">
            {t('tank_selection.tank')}
            {': '}
            {tankNumber}
            <br />
            {t('tank_selection.certificate')}
            {': '}
            {certificate.id}
            <br />
            {t('tank_selection.valid_until')}
            {': '}
            <Typography variant="body2" component="span" sx={{ color: dateColor() }}>
              {certificateDate.toLocaleDateString()}
            </Typography>
            <br />
            {hasBeenChecked && (
              <Typography variant="body2">
                {t('tank_selection.checked_by')}
                {': '}
                {validation.operator}
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
      {certificateExpired && (
      <Alert
        sx={{
          padding: 1, mt: 2, justifyContent: 'center',
        }}
        severity="warning"
      >
        {t('tank_selection.certificate_expired')}
      </Alert>
      )}
      {certificateExpiresSoon && (
      <Alert
        sx={{
          padding: 1, mt: 2, justifyContent: 'center',
        }}
        severity="warning"
      >
        {t('tank_selection.certificate_expires_soon')}
      </Alert>
      )}
      {displayVerify && (
      <ValidationForm
        tankPath={tankPath}
        tankNumber={tankNumber}
        certificateNumber={certificate.id}
        transporterName={transporterName}
        setLoadingState={setLoadingState}
        verify={verifyAndDisplay}
      />
      )}
      {hasBeenChecked && !isValid && (
      <Alert
        sx={{
          padding: 1, mt: 2, justifyContent: 'center',
        }}
        severity="error"
      >
        {t('tank_selection.certificate_invalid')}
      </Alert>
      )}
    </Paper>
  );
}
