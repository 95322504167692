import lz from 'lz-string';
import { v4 as uuidv4 } from 'uuid';

export const paConversionDeeplink = (pureAlcoholResults, forms) => {
  const compressedForms = lz.compressToEncodedURIComponent(JSON.stringify(forms));
  const compressedPAResults = lz.compressToEncodedURIComponent(JSON.stringify(pureAlcoholResults));
  const hookUrl = new URL(`${window.location.origin}${window.location.pathname}`);
  const hookUrlParams = new URLSearchParams(window.location.search);
  hookUrl.searchParams.append('tankPath', hookUrlParams.get('tankPath'));
  hookUrl.searchParams.append('forms', compressedForms);
  hookUrl.searchParams.append('paResults', compressedPAResults);
  const volumeDeeplink = new URL('https://alcoholometry.labox-apps.com/deeplink');
  // const volumeDeeplink = new URL('http://localhost:3001/deeplink');
  volumeDeeplink.searchParams.append('type', 'volumePAConversion');
  volumeDeeplink.searchParams.append('hookName', 'Digitank Camions Citernes');
  volumeDeeplink.searchParams.append('hookUrl', hookUrl.href);
  volumeDeeplink.searchParams.append('volumeUnit', 'hL');
  volumeDeeplink.searchParams.append('deeplinkReturnId', `pa-res-${uuidv4().substr(0, 4)}`);
  // console.log('hookUrl: ', hookUrl.href.length);
  // console.log('fullUrl: ', volumeDeeplink.href.length);
  // console.log(volumeDeeplink.href);
  // window.open(volumeDeeplink.href, '_blank');
  window.location.assign(volumeDeeplink.href);
};

export const paConversionNavigation = (pureAlcoholResults, forms, tankPath, navigate) => {
  navigate('../paVolumeConversion', {
    state: {
      deeplinkReturnId: `pa-res-${uuidv4().substr(0, 4)}`,
      forms,
      paResults: pureAlcoholResults,
      tankPath,
    },
  });
};
