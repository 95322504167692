import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
  collection,
  getCountFromServer,
} from 'firebase/firestore';
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTranslation } from 'react-i18next';
import { useFirestore } from 'reactfire';

const PAGE_SIZE = 30;

export default function OurPagination({
  collectionPath, listOfConditions, orderByAttribute, list, filters, displayEmpty = false,
}) {
  const [pages, setPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation();
  const [pageState, setPageState] = useState('initial');
  const [numberOfPages, setNumberOfPages] = useState('');
  const db = useFirestore();

  const loadPage = useCallback(async (index, updatedPages, updatedListOfConditions) => {
    setPageState('loading');
    // If we've already loaded this page, we don't need to fetch it again
    const pagesLength = updatedPages.length;
    if (index < pagesLength) {
      setPageState('loaded');
      return;
    }

    const collectionRef = collection(db, collectionPath);
    let queryDocs;
    // If we're not on the first page, start after the last document of the previous page
    if (index > 0) {
      queryDocs = query(
        collectionRef,
        ...updatedListOfConditions,
        orderBy(orderByAttribute, 'desc'),
        startAfter(updatedPages[index - 1][PAGE_SIZE - 1]),
        limit(PAGE_SIZE),
      );
    } else {
      const countQuery = query(
        collectionRef,
        ...updatedListOfConditions,
        orderBy(orderByAttribute, 'desc'),
      );
      const countSnap = await getCountFromServer(countQuery);
      setNumberOfPages(Math.ceil(countSnap.data().count / PAGE_SIZE));
      queryDocs = query(
        collectionRef,
        ...updatedListOfConditions,
        orderBy(orderByAttribute, 'desc'),
        limit(PAGE_SIZE),
      );
    }

    const querySnapshot = await getDocs(queryDocs);
    const { docs } = querySnapshot;

    const newPages = [...updatedPages];

    if (index < newPages.length) {
      newPages.splice(index, 1, docs);
    } else {
      newPages[index] = docs;
    }

    setPages(newPages);
    setPageState('loaded');
  }, [collectionPath, db, orderByAttribute]);

  const initSearch = useCallback((conditions) => {
    setPages([]);
    setPageIndex(0);
    loadPage(0, [], conditions);
  }, [loadPage]);

  useEffect(() => {
    if (pageState === 'initial' && !displayEmpty) {
      initSearch(listOfConditions);
    }
  }, [initSearch, pageState, listOfConditions, displayEmpty]);

  const nextPage = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
    loadPage(pageIndex + 1, pages, listOfConditions);
  };

  const prevPage = () => {
    setPageIndex((prevPageIndex) => prevPageIndex - 1);
    loadPage(pageIndex - 1, pages, listOfConditions);
  };

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%',
      }}
    >
      {React.cloneElement(filters, {
        initSearch,
      })}
      {list && pages[pageIndex] && React.cloneElement(list, {
        docs: pages[pageIndex],
        initSearch,
      })}
      {pageState === 'loading' && <CircularProgress />}
      <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
      }}
      >

        <Button
          disabled={pageIndex === 0}
          variant="text"
          color="inherit"
          startIcon={<NavigateBeforeIcon />}
          onClick={prevPage}
        >
          {t('navigation.previous_page')}
        </Button>
        <Typography>{`Page: ${pageIndex + 1} / ${numberOfPages === 0 ? '1' : numberOfPages}`}</Typography>
        <Button
          disabled={pages[pageIndex]?.length < PAGE_SIZE}
          variant="text"
          color="inherit"
          endIcon={<NavigateNextIcon />}
          onClick={nextPage}
        >
          {t('navigation.next_page')}
        </Button>
      </Box>
    </Box>
  );
}
