import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  where,
} from 'firebase/firestore';
import { useUser } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import OurPagination from 'components/OurPagination';
import TankDetail from './TankDetail';
import TankListHeaders from './TankListHeaders';
import TankListItem from './TankListItem';
import TankFilters from './TankFilters';

export default function Tanks() {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [globalState] = useGlobal();

  const computeListOfConditions = useCallback((formikValues) => {
    let conditions = [
      where('lastModified', '<=', formikValues.endDate),
      where('lastModified', '>=', formikValues.startDate),
    ];
    if (formikValues.transporterName !== '') {
      conditions = [...conditions, where('transporterName', '==', formikValues.transporterName)];
    }
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    transporterName: '',
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 2);
    return d;
  }

  function initialEndDate() {
    const d = new Date();
    d.setHours(23, 59, 0, 0); // next before midnight
    return d;
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_tanks', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.tanks_management')}
      </Typography>

      {globalState.activeOrganization !== '' && (
        <OurPagination
          collectionPath={`organizations/${globalState.activeOrganization}/apps/digitank-transporter/tanks`}
          listOfConditions={listOfConditions}
          orderByAttribute="lastModified"
          filters={(
            <TankListFilters
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
            />
          )}
          list={(
            <TankList listOfConditions={listOfConditions} />
          )}
        />
      )}
      {globalState.activeOrganization === '' && <CircularProgress />}

      <Fab
        color="primary"
        aria-label="create"
        sx={{
          position: 'fixed', bottom: '5%', right: '5%',
        }}
        component={Link}
        to="create"
        variant="extended"
      >
        <AddIcon />
        {t('tank_creation.add_button')}
      </Fab>
    </Box>
  );
}

function TankList({ docs, initSearch, listOfConditions }) {
  const [activeTank, setActiveTank] = useState('');
  const [open, setOpen] = useState(false);
  const [tanks, setTanks] = useState([]);
  const [tanksPath, setTanksPath] = useState({});

  const refresh = useCallback(async () => {
    const allTanks = [];
    const allTanksPaths = {};

    for (const tankDoc of docs) {
      const data = tankDoc.data();
      allTanks.push({ transporter: data.transporterName, ...data });
      allTanksPaths[tankDoc.data().id + data.transporterName] = tankDoc.ref.path;
    }
    setTanks(allTanks);
    setTanksPath(allTanksPaths);
  }, [docs]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleOpen = (tank) => {
    setActiveTank(tank);
    setOpen(true);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <TankListHeaders />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {tanks.map((tank, i) => (
            <div key={tank.id}>
              <TankListItem index={i} tank={tank} handleOpen={handleOpen} />
              <Divider />
            </div>
          ))}
        </List>
      </Paper>
      <TankDetail
        open={open}
        setOpen={setOpen}
        tank={activeTank}
        tankPath={tanksPath[activeTank.id + activeTank.transporter]}
        tankDeleteCallback={() => initSearch(listOfConditions)}
      />
    </Box>
  );
}

function TankListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
}) {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    transporterName: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: formikInitValues,
    validationSchema,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <TankFilters formik={formik} datePickersLabel={t('admin.last_modified')} />
    </Box>
  );
}
