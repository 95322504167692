import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  useSigninCheck,
  useUser,
  useFirestore,
} from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import {
  doc, getDoc,
  disableNetwork,
  enableNetwork,
  addDoc,
  collection,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import { MenuProvider } from 'menu-actions/MenuContext';
import {
  BrowserRouter, Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import StoragePDFViewerPage from 'components/StoragePDFViewerPage';
import MyAccount from 'components/account/MyAccount';
import Admin from 'components/admin/Admin';
import cgu from 'staticFiles/CGU.pdf';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from 'react-i18next';
import GlobalSnackbar from 'components/GlobalSnackbar';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import { Cookies, Contact } from 'labox-ws-commons';
import { getAnalytics } from 'firebase/analytics';
import cgv from 'staticFiles/CGV.pdf';
import privacyFR from 'staticFiles/privacy-policy-fr.pdf';
import privacyEN from 'staticFiles/privacy-policy-en.pdf';
import SignInPage from './SignInPage';
import TankSelection from './tankSelection/TankSelection';
import Operation from './operation/Operation';
import TopButtons from './TopButtons';
import Footer from './Footer';
import PDFView from './PDFView';
import BatchSelection from './volumeConversion/BatchSelection';
import BatchTempAndTav from './volumeConversion/BatchTempAndTav';
import VolumeConversionResults from './volumeConversion/VolumeConversionResults';
import LoadingPage from './LoadingPage';
import PAVolumeConversionForm from './paVolumeConversion/PAVolumeConversionForm';

export default function Home() {
  return <RemoteConfigStatus defaultComponent={<UserSetupMenuAndMainPage />} />;
}

function UserSetupMenuAndMainPage() {
  const { status } = useSigninCheck();
  const { data: user } = useUser();
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function getUser(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function getUserPrivateData(uid) {
      const userRef = doc(db, 'users', uid, 'private', 'data');
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function fetchMultipleTimesUser(uid) {
      let firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(1000);
        firestoreUser = await getUser(uid);
        if (!firestoreUser.exists()) {
          await await sleep(3000);
          firestoreUser = await getUser(uid);
        }
      }
      return firestoreUser;
    }

    function initVolumeConversionMethod(userVolumeConversionMethod) {
      // If the user selected a volume conversion method in the settings,
      // it will be saved in the local storage. Otherwise, we look in the firestore
      // and else the default one: OIML-OIV
      const storageVolumeConversionMethod = localStorage.getItem('volume_conversion_method');
      if (!storageVolumeConversionMethod) {
        if (userVolumeConversionMethod === 'traditional') {
          globalActions.setVolumeConversionMethod(userVolumeConversionMethod);
        } else {
          globalActions.setVolumeConversionMethod('OIML-OIV');
        }
      } else {
        globalActions.setVolumeConversionMethod(storageVolumeConversionMethod);
      }
    }

    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');
      await fetchMultipleTimesUser(user.uid);
      const firestoreUserPrivateData = await getUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      globalActions.setActiveOrganization(userPrivateData.activeOrganization);

      if (userPrivateData.activeOrganization === '') {
        return globalActions.setUserStatus('notYetSetup');
      }

      const organizationAppRef = doc(
        db,
        `/organizations/${userPrivateData.activeOrganization}`
        + '/apps/digitank-tanker-trucks',
      );
      const firestoreorganizationApp = await getDoc(organizationAppRef);
      const organizationAppData = firestoreorganizationApp.data();

      const organizationPlan = organizationAppData.plan;
      globalActions.setUserPlan(organizationPlan);
      if (organizationAppData.admins.includes(user.uid)) {
        globalActions.setUserRoles(['admin']);
      }
      const userVolumeConversionMethod = organizationAppData.volumeConversionMethod;
      if (['premium', 'test_premium'].includes(organizationPlan)) {
        globalActions.setVolumeConversionEnabled(true);
      }
      const pureAlcoConversionOperationButton = organizationAppData.pureAlcoConversionOperationButton || false;
      if (pureAlcoConversionOperationButton) {
        globalActions.setPureAlcoConversionOperationButtom(true);
      }

      initVolumeConversionMethod(userVolumeConversionMethod);
      return globalActions.setUserStatus('setupDone');
    }

    function setupFirestoreNetwork() {
      if (globalState.networkState === 'offline') {
        // console.log('FS desable network at setup');
        disableNetwork(db);
      }
    }

    if (user?.uid !== undefined && globalState.userStatus === 'initial') {
      setupGlobalStateFromUser();
      setupFirestoreNetwork();
    }
  }, [db, globalActions, globalState.networkState, globalState.userStatus, user?.uid]);

  window.ononline = (event) => {
    enableNetwork(db);
    globalActions.setNetworkState('online');
  };

  window.onoffline = (event) => {
    disableNetwork(db);
    globalActions.setNetworkState('offline');
  };

  if (status === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return <MenuProvider app={<MainPage />} />;
}

function MainPage() {
  const [globalState] = useGlobal();
  const [displayAll, setdisplayAll] = useState(false);
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {globalState.networkState === 'offline' && (
        <Alert
          sx={{
            padding: 1, mt: 1, justifyContent: 'center',
          }}
          severity="error"
          onClick={() => setdisplayAll(!displayAll)}
        >
          {t('offline')}
          <Button sx={{ ml: 1, height: 17 }} color="inherit" variant="outlined" size="small">
            {expandIcon()}
          </Button>
          <Collapse
            in={displayAll}
            sx={{
              width: '100%',
            }}
          >
            {t('offline_more')}
          </Collapse>
        </Alert>
        )}
        <Box sx={{
          minHeight: '80vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <GlobalSnackbar />
          <TopButtons />
          <MainPageContent />
        </Box>
        <Footer />
        <Cookies
          cookieName="agreed-to-cookies-on-digitank-tanker-trucks"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function MainPageContent(props) {
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!signInCheckResult?.signedIn === true
      && location.pathname !== '/contact'
      && location.pathname !== '/cgu'
      && location.pathname !== '/cgv'
      && location.pathname !== '/cgu-cgv'
      && location.pathname !== '/privacy-policy') {
      navigate('/signin');
    }
  }, [location.pathname, navigate, signInCheckResult?.signedIn]);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[Camions Citernes, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  function privacyPolicy() {
    if (t('locale') === 'fr') {
      return privacyFR;
    }
    return privacyEN;
  }

  return (
    <Routes>
      <Route path="/" element={<TankSelectionOrLoading />} />
      <Route path="operation/*" element={<Operation />} />
      <Route path="paVolumeConversion" element={<PAVolumeConversionForm />} />
      <Route path="volumeConversion" element={<BatchSelection />} />
      <Route path="volumeConversion/tempAndTav" element={<BatchTempAndTav />} />
      <Route path="volumeConversion/tempAndTav/result" element={<VolumeConversionResults />} />
      <Route
        path="pdfViewer/*"
        element={<StoragePDFViewerPage />}
      />
      <Route exact path="account" element={<MyAccount />} />
      <Route exact path="signin" element={<SignInPage />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
        )}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route path="privacy-policy" element={<PDFView path={privacyPolicy()} />} />
      <Route path="admin/*" element={<Admin />} />
      <Route
        path="*"
        element={(
          <main style={{ padding: '1rem' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>There's nothing here!</p>
          </main>
            )}
      />
    </Routes>
  );
}

function TankSelectionOrLoading() {
  const [globalState] = useGlobal();
  if (globalState.userStatus === 'initial') {
    <CircularProgress />;
  }
  return <TankSelection />;
}
