import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import SnackBarError from 'components/SnackBarError';
import { useLocation, useNavigate } from 'react-router-dom';
import useMenu from 'menu-actions/useMenu';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import OperationButtons from './OperationButtons';
import { roundNoFixed } from '../utils';
import CompartmentsTable from './CompartmentsTable';
import {
  storeGetCpt,
  storeGetOperation,
  storeOperationIdFromPath,
  storeSetCpt,
  storeSetCpts,
  storeClearOperation,
} from './compartmentStore';
import PureAlcoholResults from './PureAlcoholResults';
import { paConversionNavigation } from './paConversionNavigation';

export default function Compartments(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    compartments, tankNumber, certificatePath, transporter, transporterName, tankPath,
  } = props;
  const [totalVolume, setTotalVolume] = useState(0);
  const listOfCompartments = compartments;
  const [forms, setForms] = useState(initForms(listOfCompartments));
  const [openError, setopenError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const { dispatchMenuActions } = useMenu();
  const analytics = getAnalytics();
  const [globalState, globalActions] = useGlobal();
  const [pureAlcoholResults, setPureAlcoholResults] = useState(initPAResults());
  const { setreturnAction } = useMenu();

  const compartmentsErrors = useMemo(() => initCompartmentsErrors(listOfCompartments), [listOfCompartments]);

  const handleGoToPAVolumeConversion = useCallback(() => {
    if (Object.keys(pureAlcoholResults).length >= 6) {
      globalActions.setSnackbarMessage({ message: t('alco_results.too_many'), severity: 'warning' });
      return;
    }
    logEvent(analytics, 'volume_pa_conversion', {
      appName: 'Digitank, Tanker Trucks',
      organization: globalState.activeOrganization,
    });
    paConversionNavigation(pureAlcoholResults, forms, tankPath, navigate);
  }, [analytics, forms, globalActions, globalState.activeOrganization,
    navigate, pureAlcoholResults, t, tankPath]);

  useEffect(() => {
    const goToPAVolumeConvAction = {
      id: 'to_pa_conversion',
      name: t('operation.to_pa_vol_conversion'),
      callback: handleGoToPAVolumeConversion,
    };
    if (globalState.volumeConversionEnabled) {
      dispatchMenuActions({
        type: 'add',
        actions: [goToPAVolumeConvAction],
      });
    }
  }, [dispatchMenuActions, globalState.volumeConversionEnabled, handleGoToPAVolumeConversion, t]);

  function errorWithMessage(aMessage) {
    seterrorMessage(aMessage);
    setopenError(true);
  }

  const setValue = (newForm, compartmentID, error) => {
    setForms(
      forms.map((form) => {
        let mergedForm = form;
        if (form.number === compartmentID) {
          mergedForm = { ...form, ...newForm };
          if (!error) {
            storeSetCpt(
              sessionStorage,
              compartmentID,
              mergedForm.height,
              mergedForm.volume,
            );
          }
        }
        return mergedForm;
      }),
    );
  };

  function initForms(initCompartments) {
    if (location && location.state && location.state.forms) {
      return location.state.forms;
    }
    return initCompartments.map((c) => {
      const sessionCpt = storeGetCpt(sessionStorage, c.id);
      return {
        number: c.id,
        height: sessionCpt ? sessionCpt.height : '',
        volume: sessionCpt ? sessionCpt.volume : '',
        nominalVolume: c.nominalVolume,
      };
    });
  }

  function initPAResults(initComartments) {
    if (location && location.state && location.state.paResults) {
      return location.state.paResults;
    }
    return {};
  }

  function initCompartmentsErrors(initCompartments) {
    const cptsErrors = {};
    for (const c of initCompartments) {
      cptsErrors[c.id] = false;
    }
    return cptsErrors;
  }

  const handleCompartmentError = (compartmentNumber, boolean) => {
    compartmentsErrors[compartmentNumber] = boolean;
  };

  useEffect(() => {
    const loadLastOperation = () => {
      const operationStoreId = storeOperationIdFromPath(certificatePath);
      const savedOperation = storeGetOperation(localStorage, operationStoreId);
      if (savedOperation) {
        try {
          setForms(savedOperation);
          storeSetCpts(
            sessionStorage,
            savedOperation.map((c) => ({ id: c.number, height: c.height, volume: c.volume })),
          );
        } catch (e) {
          errorWithMessage(t('operation.no_saved_operation'));
          storeClearOperation(localStorage, operationStoreId);
        }
      } else {
        errorWithMessage(t('operation.no_saved_operation'));
      }
    };
    const loadLastOperationAction = {
      name: t('operation.load_last_operation'),
      callback: loadLastOperation,
    };
    const handleGoToPDFViewer = () => {
      navigate(`/pdfViewer?filePath=${encodeURIComponent(`gs://labox-ws.appspot.com/${certificatePath}.pdf`)}`);
    };
    const goToCertifPdfAction = {
      name: t('storage.certificate_pdf'),
      callback: handleGoToPDFViewer,
    };
    dispatchMenuActions({
      type: 'add',
      actions: [loadLastOperationAction, goToCertifPdfAction],
    });
    setreturnAction(() => () => navigate('/'));
  }, [dispatchMenuActions, certificatePath, navigate, t, setreturnAction]);

  useEffect(() => {
    const hasErrors = Object.values(compartmentsErrors).some(
      (boolean) => boolean,
    );
    if (!hasErrors) {
      const total = forms
        .map((compt) => Number(compt.volume))
        .reduce((currentTotal, volume) => roundNoFixed(currentTotal + volume, 4));
      setTotalVolume(total);
    }
  }, [compartmentsErrors, forms]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <PureAlcoholResults
        pureAlcoholResults={pureAlcoholResults}
        setPureAlcoholResults={setPureAlcoholResults}
        handleGoToPAVolumeConversion={handleGoToPAVolumeConversion}
      />
      <CompartmentsTable
        compartments={listOfCompartments}
        forms={forms}
        setValue={setValue}
        handleCompartmentError={handleCompartmentError}
      />
      <b>
        {t('operation.total_volume')}
        {': '}
        {totalVolume === '' ? 0 : totalVolume}
      </b>
      <OperationButtons
        hasErrors={Object.values(compartmentsErrors).some(
          (boolean) => boolean,
        )}
        forms={forms}
        tankNumber={tankNumber}
        certificatePath={certificatePath}
        transporter={transporter}
        transporterName={transporterName}
        totalVolume={totalVolume}
        pureAlcoholResults={pureAlcoholResults}
      />
      <SnackBarError
        severity="warning"
        openError={openError}
        errorMessage={errorMessage}
        setopenError={setopenError}
      />
    </Box>
  );
}
