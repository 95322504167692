import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import useGlobal from 'global-state/store';
import Alert from '@mui/material/Alert';

export default function BatchSelection() {
  const { t } = useTranslation();
  const location = useLocation();
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const colors = ['#437b89', '#f4ae43', '#99bd5b', '#000000', '#4d90fe', '#49d555', '#cb7272', '#d549c0', '#d5cf49'];
  const [batches, setBatches] = useState(initBatches(location.state.compartments));
  const [operation] = useState(location.state.operation);
  const [globalState] = useGlobal();

  useEffect(() => {
    logEvent(analytics, 'volume_conversion_batch_selection', {
      appName: 'Digitank, Tanker Trucks',
      organization: globalState.activeOrganization,
    });
  }, [analytics, globalState.activeOrganization]);

  function initBatches(compartments) {
    const savedBatchesString = sessionStorage.getItem('selectedBatches');
    if (savedBatchesString) {
      return JSON.parse(savedBatchesString);
    }
    const notEmptyCompartments = compartments.filter((comp) => comp.volume);
    return notEmptyCompartments.map((comp, i) => (
      {
        number: i + 1,
        color: colors[i],
        compartments: [{ id: comp.number, volume: comp.volume }],
      }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChange = (compartment, oldBatchNumber, newBatchNumber) => {
    const newBatches = [...batches];
    const oldBatch = newBatches.find((batch) => batch.number === oldBatchNumber);
    const newBatch = newBatches.find((batch) => batch.number === newBatchNumber);
    const newCompartmentsOfOldBatch = oldBatch
      .compartments.filter((comp) => comp.id !== compartment.id);
    oldBatch.compartments = newCompartmentsOfOldBatch;
    newBatch.compartments.push(compartment);
    setBatches(newBatches);
  };

  const handleGoToTempAndTav = () => {
    sessionStorage.setItem('selectedBatches', JSON.stringify(batches));
    sessionStorage.removeItem('batchesTempAndTav');
    navigate('tempAndTav', {
      state: {
        batches,
        operation,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
          width: '100%',
        }}
      >
        <Typography variant="h6" component="span">
          {t('volume_conversion.batch_selection')}
        </Typography>
        {globalState.userPlan === 'test_premium'
              && (
              <Alert
                style={{ justifyContent: 'center', alignItems: 'center' }}
                severity="warning"
              >
                {t('comm.test_premium_volume_conversion')}
              </Alert>
              )}
        {batches?.map((batch) => (
          <div key={batch.number}>
            <Batch batch={batch} handleChange={handleChange} batchesNumbers={batches.map((b) => b.number)} />
          </div>
        ))}
        <Button
          variant="contained"
          size="large"
          onClick={handleGoToTempAndTav}
        >
          {t('volume_conversion.title')}
        </Button>
      </Box>

    </form>
  );
}

function Batch(props) {
  const {
    batch, handleChange, batchesNumbers,
  } = props;
  const { t } = useTranslation();

  if (batch.compartments.length === 0) {
    return <Box />;
  }

  return (
    <Box sx={{
      display: 'flex',
      gap: 2,
      flexDirection: 'column',
      borderStyle: 'solid',
      borderWidth: 'medium',
      borderColor: batch.color,
      borderRadius: 5,
      width: '80vw',
      maxWidth: 600,
      minHeight: 70,
      p: 2,
    }}
    >
      <Typography component="span" sx={{ alignSelf: 'flex-start', fontWeight: 'bold', color: batch.color }}>
        {t('volume_conversion.batch_number')}
      </Typography>
      {batch.compartments.map((compartment) => (
        <div key={compartment.id}>
          <CompartmentDetail
            compartment={compartment}
            handleChange={handleChange}
            batchesNumbers={batchesNumbers}
            batch={batch}
          />
        </div>
      ))}
    </Box>
  );
}

function CompartmentDetail(props) {
  const {
    batch, handleChange, batchesNumbers, compartment,
  } = props;
  const { t } = useTranslation();
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: 1,
      }}
    >
      <FormControl sx={{ backgroundColor: 'white', minWidth: 60 }}>
        <Select
          id="batchSelection"
          name="batchSelection"
          value={batch.number}
          onChange={(event) => handleChange(compartment, batch.number, event.target.value)}
        >
          {batchesNumbers.map((batchNumber) => (
            <MenuItem value={batchNumber} key={batchNumber}>
              {batchNumber}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
      <Box sx={{
        display: 'flex', gap: 2, flexDirection: 'column', width: '100%',
      }}
      >
        <Typography component="span" sx={{ fontWeight: 'bold' }}>
          {t('operation.compartment')}
          {': '}
          {compartment.id}
        </Typography>
        <Typography component="span">
          {'Volume: '}
          {compartment.volume}
          {' HL'}
        </Typography>

      </Box>
    </Paper>
  );
}
