import React, { useState, useEffect } from 'react';
import { useFirestore, useUser } from 'reactfire';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  Select, MenuItem, Paper, Typography,
} from '@mui/material';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

function OrganizationsSelect({ changeCallback }) {
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { data: user } = useUser();
  const [organizations, setOrganizations] = useState([]);
  const [activeOrganization, setActiveOrganization] = useState('');
  const [loadingState, setloadingState] = useState('initial');
  const [organizationNames, setOrganizationNames] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrganizations = async () => {
      const userPrivateDataRef = doc(db, 'users', user.uid, 'private', 'data');
      const userPrivateDataDoc = await getDoc(userPrivateDataRef);
      const data = userPrivateDataDoc.data();
      setOrganizations(['', ...data.organizations]);
      const names = {};
      await Promise.all(
        data.organizations.map(async (organizationId) => {
          const organizationRef = doc(db, 'organizations', organizationId);
          const organizationDoc = await getDoc(organizationRef);
          const organizationData = organizationDoc.data();
          names[organizationId] = organizationData.name;
        }),
      );
      names[''] = t('none');
      setOrganizationNames(names);
      setloadingState('loaded');
    };

    if (user && loadingState === 'initial' && !['initial', 'loading'].includes(globalState.userStatus)) {
      setloadingState('loading');
      setActiveOrganization(globalState.activeOrganization);
      fetchOrganizations();
    }
  }, [db, globalState.activeOrganization, globalState.userStatus, loadingState, t, user]);

  const handleOrganizationChange = async (event) => {
    const newActiveOrganization = event.target.value;
    setActiveOrganization(newActiveOrganization);
    globalActions.setActiveOrganization(newActiveOrganization);
    const userPrivateDataRef = doc(db, 'users', user.uid, 'private', 'data');
    await updateDoc(userPrivateDataRef, {
      activeOrganization: newActiveOrganization,
    });
    globalActions.setUserStatus('initial');
    changeCallback();
  };

  if (loadingState !== 'loaded') {
    return <CircularProgress />;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mx: 1,
        py: 2,
        px: 1,
        width: '100%',
        maxWidth: 800,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography variant="h6">
        {t('organization')}
        {' :'}
      </Typography>
      <Select
        displayEmpty
        size="small"
        value={activeOrganization}
        onChange={handleOrganizationChange}
      >
        {organizations.map((organizationId) => (
          <MenuItem key={organizationId} value={organizationId}>
            {organizationNames[organizationId]}
          </MenuItem>
        ))}
      </Select>
    </Paper>

  );
}

export default OrganizationsSelect;
