import {
  doc, setDoc, Timestamp,
} from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import { storeNewOperationInArray } from './compartmentStore';

export default async function saveOperationToFirestore(
  db,
  clientPath,
  analytics,
  user,
  tankNumber,
  certificatePath,
  transporter,
  transporterName,
  compartmentsHeightsAndVolumes,
  totalVolume,
  volumeConversionResults,
  volumeConversionMethod = 'OIML-OIV',
) {
  const operationId = `${tankNumber}-${Timestamp.now().toMillis()}`;
  const userUid = user.uid;
  const userEmail = user.email;
  const operation = {
    tankNumber,
    certificatePath,
    certificateNumber: certificatePath.toString().split('/')[7],
    organizationTransporter: transporter,
    transporterName,
    compartmentsHeightsAndVolumes,
    dateAndTime: Timestamp.now(),
    id: operationId,
    totalVolume,
    userUid,
    userEmail,
  };
  if (volumeConversionResults) {
    operation.volumeConversionResults = volumeConversionResults;
    operation.volumeConversionMethod = volumeConversionMethod;
  }
  storeNewOperationInArray(localStorage, operation);
  await setDoc(doc(db, clientPath, 'operations', operationId), operation);
  const clientPathSegments = clientPath.split('/');
  const organization = clientPathSegments[1];
  logEvent(analytics, 'save_operation', {
    user_uid: user?.uid,
    appName: 'Digitank, Tanker Trucks',
    organization,
    tank_id: tankNumber,
  });
}
