import React, { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { getStoredOperationsArray } from 'components/operation/compartmentStore';
import OperationDetails from 'components/admin/monitoringOperations/OperationDetails';
import OperationVolumeConversion from 'components/admin/monitoringOperations/OperationVolumeConversion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {
  Box, Collapse, Divider, IconButton,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';

export default function LastOperationsDialog(props) {
  const { t } = useTranslation();
  const {
    setOpen, open,
  } = props;
  const lastOperations = useMemo(() => getStoredOperationsArray(localStorage), []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {lastOperations.map((operation) => (
          <div key={operation.id}>
            <Item operation={operation} />
            <Divider />
          </div>
        ))}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Item({ operation }) {
  const { t } = useTranslation();
  const [displayAll, setdisplayAll] = useState(false);
  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  const date = new Date((operation.dateAndTime.seconds * 1000) + (operation.dateAndTime.nanoseconds / 1000000));

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => setdisplayAll(!displayAll)}
      >
        <Typography>{date.toLocaleString()}</Typography>
        <IconButton>
          {expandIcon()}
        </IconButton>
      </Box>

      <Collapse
        in={displayAll}
        sx={{
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: 1,
          pb: 3,
        }}
        >
          <OperationDetails organizationName="" operation={operation} />
          {operation.volumeConversionResults
        && (<OperationVolumeConversion batchResults={operation.volumeConversionResults} />)}
          {operation.volumeConversionMethod
        && (
        <Typography component="span">
          {t('settings.volume_calculation_method')}
          {': '}
          {operation.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method'))}
          {operation.volumeConversionMethod !== 'traditional'
         && ('OIML-OIV')}
        </Typography>
        )}
        </Box>

      </Collapse>
    </Box>
  );
}
