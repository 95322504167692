import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { unit } from 'mathjs';
import { unitFormatPrecision, unitMasks } from 'components/units/UnitDefinitionsAndMasks';
import { round, searchInCompartmentArray } from 'components/utils';
import CompartmentField from './CompartmentField';
import validationSchema from './ValidationSchema';

function convert(
  value,
  arrayToSearch,
  conversion,
  inputUnitLabel,
  inputUnitCompute,
  outputUnitLabel,
  outputUnitCompute,
) {
  if (value === null || value === '') {
    return '';
  }
  const inputValue = round(
    unit(value, inputUnitLabel).toNumber(inputUnitCompute),
    unitFormatPrecision,
  );
  const outputValue = searchInCompartmentArray(
    inputValue,
    arrayToSearch,
    conversion,
  );
  return round(
    unit(outputValue, outputUnitCompute).toNumber(outputUnitLabel),
    unitMasks[outputUnitLabel],
  );
}

export default function CompartmentForm(props) {
  const {
    compartment,
    form,
    handleCompartmentError,
    headers,
    inputStyle,
    layoutStyle,
    setValue,
  } = props;
  const { t } = useTranslation();
  const [highlightment, setHighlightment] = React.useState({
    height: false,
    volume: false,
  });
  const [fields, setFields] = React.useState({
    height: {
      name: 'height',
      unitLabel: 'mm',
      unitCompute: 'm',
      searchUnitLabel: 'hL',
      searchUnitCompute: 'L',
      conversion: compartment.volumeToHeight,
      arrayToSearch: Object.keys(compartment.volumeToHeight)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
      error: null,
    },
    volume: {
      name: 'volume',
      unitLabel: 'hL',
      unitCompute: 'L',
      searchUnitLabel: 'mm',
      searchUnitCompute: 'm',
      conversion: compartment.heightToVolume,
      arrayToSearch: Object.keys(compartment.heightToVolume)
        .map((x) => Number(x))
        .sort((a, b) => a - b),
      error: null,
    },
  });

  const schema = validationSchema(
    t,
    unit(compartment.minHeight, 'm').toNumber('mm'),
    unit(compartment.maxHeight, 'm').toNumber('mm'),
    unit(compartment.minVolume, 'L').toNumber('hL'),
    unit(compartment.maxVolume, 'L').toNumber('hL'),
  );

  const setUpdate = async (value, name) => {
    const innerForm = { [name]: value };
    const field = fields[name];
    const otherFieldName = Object.keys(fields).find((fname) => fname !== name);
    const otherField = fields[otherFieldName];
    try {
      await schema.validate(innerForm);
      handleCompartmentError(form.number, false);
      innerForm[otherFieldName] = convert(
        value,
        otherField.arrayToSearch,
        otherField.conversion,
        otherField.searchUnitLabel,
        otherField.searchUnitCompute,
        otherField.unitLabel,
        otherField.unitCompute,
      );
      setValue(innerForm, form.number, false);
      setFields({
        [name]: { ...field, error: null },
        [otherFieldName]: {
          ...otherField,
          error: null,
        },
      });
      setHighlightment({
        [name]: false,
        [otherFieldName]: value !== form[name],
      });
      setTimeout(removeHighlight, 300, otherFieldName);
    } catch (err) {
      handleCompartmentError(form.number, true);
      setValue(innerForm, form.number, true);
      setFields({
        ...fields,
        [name]: { ...field, error: err.message },
      });
    }
  };

  const removeHighlight = (fieldname) => {
    setHighlightment({
      ...highlightment,
      [fieldname]: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit} style={layoutStyle}>
      {headers}
      <CompartmentField
        name={fields.height.name}
        error={fields.height.error}
        inputMode="numeric"
        decimalScale={0}
        inputStyle={inputStyle}
        setUpdate={setUpdate}
        value={form.height}
        highlighted={highlightment.height}
      />
      <CompartmentField
        name={fields.volume.name}
        error={fields.volume.error}
        decimalScale={4}
        inputStyle={inputStyle}
        setUpdate={setUpdate}
        value={form.volume}
        highlighted={highlightment.volume}
      />
    </form>
  );
}
