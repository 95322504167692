import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default function CertificateListHeaders() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        rowGap: 1,
      }}
    >
      <Typography>
        {t('admin.tank_id')}
      </Typography>
      <Typography>
        {t('tank_selection.valid_until')}
      </Typography>
    </Box>
  );
}
