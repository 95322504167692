import React from 'react';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';

// to be removed when there is more than one input here
// eslint-disable-next-line import/prefer-default-export
export function NumberInput(props) {
  const {
    stretch, label, onBlur, id, decimalScale, value, onChange, onKeyPress, name, inputcolor, inputMode, highlighted,
  } = props;
  const inputWidth = stretch ? '100%' : 'auto';
  const regularStyle = {
    backgroundColor: inputcolor || '#fff',
    transition: 'box-shadow 0.15s linear',
    borderRadius: '4px',
    width: inputWidth,
  };
  const highlightedStyle = {
    ...regularStyle,
    boxShadow: 'inset 0px 0px 7px 0px #437a89',
  };

  const myInputMode = inputMode || 'decimal';

  return (
    <NumericFormat
      name={name}
      value={value}
      decimalScale={decimalScale}
      id={id}
      onBlur={onBlur}
      label={label}
      sx={highlighted ? highlightedStyle : regularStyle}
      className="TextFieldBackground"
      variant="outlined"
      autoComplete="off"
      valueIsNumericString
      allowNegative={false}
      allowedDecimalSeparators={['.', ',']}
      decimalSeparator="."
      onKeyPress={onKeyPress}
      type="text"
      inputProps={{ inputMode: myInputMode }}
      customInput={TextField}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
    />
  );
}
