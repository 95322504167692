import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Routes, Route, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Truck from '@mui/icons-material/LocalShipping';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import RuleIcon from '@mui/icons-material/Rule';
import ArticleIcon from '@mui/icons-material/Article';
import TankCreation from './managingTanks/creation/TankCreation';
import Tanks from './managingTanks/Tanks';
import Operations from './monitoringOperations/Operations';
import Validations from './monitoringValidations/Validations';
import CertificatesMonitoring from './CertificatesMonitoring';
import Certificates from './managinCertificates/Certificates';

export default function Admin() {
  return (
    <Routes>
      <Route path="operations" element={<Operations />} />
      <Route path="validations" element={<Validations />} />
      <Route path="tanks" element={<Tanks />} />
      <Route path="tanks/create" element={<TankCreation />} />
      <Route path="certificates" element={<Certificates />} />
      <Route path="" element={<AdminReception />} />
      <Route
        path="*"
        element={(
          <main style={{ padding: '1rem' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>There's nothing here!</p>
          </main>
        )}
      />
    </Routes>
  );
}

function AdminReception() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 3,
        px: { xs: 2, md: 10 },
        mt: { xs: 5, md: 10 },
        mb: 15,
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          width: '100%', maxWidth: 300, textAlign: 'left', mt: 3,
        }}
      >
        {t('admin.monitoring')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3,
        }}
      >
        <MenuButton
          to="/admin/operations"
          icon={<FormatListBulleted sx={{ fontSize: 150 }} />}
          text={t('admin.operations_management')}
        />
        <MenuButton
          to="/admin/validations"
          icon={<RuleIcon sx={{ fontSize: 150 }} />}
          text={t('admin.validations_management')}
        />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ width: '100%', maxWidth: 300, textAlign: 'left' }}
      >
        {t('admin.management')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3,
        }}
      >
        <MenuButton
          to="tanks"
          icon={<Truck sx={{ fontSize: 150 }} />}
          text={t('admin.tanks_management')}
        />
        <MenuButton
          to="certificates"
          icon={<ArticleIcon sx={{ fontSize: 150 }} />}
          text={t('admin.certificates')}
        />
        <MenuButton
          component="a"
          target="_blank"
          href="https://orgs-ws.labox-apps.com"
          icon={<PeopleAlt sx={{ fontSize: 150 }} />}
          text={t('admin.users_management')}
        />
      </Box>
      <Typography
        variant="h6"
        component="div"
        sx={{ width: '100%', maxWidth: 300, textAlign: 'left' }}
      >
        {t('admin.monitoring')}
        :
      </Typography>
      <Box
        sx={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 3,
        }}
      >
        <CertificatesMonitoring />
      </Box>
    </Box>
  );
}

function MenuButton(props) {
  const {
    disabled, to, icon, text, component, href, target,
  } = props;

  return (
    <Button
      disabled={disabled}
      color="info"
      sx={{
        p: 2, gap: 2, display: 'flex', flexDirection: 'column', width: 300,
      }}
      variant="contained"
      component={component || Link}
      href={href}
      target={target}
      to={to}
    >
      {icon}
      {text}
    </Button>
  );
}
