import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  FirebaseAppProvider,
} from 'reactfire';
import AllReactFireProviders from 'components/AllReactFireProviders';
import { initI18next } from './i18n/i18next';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'tt.digitank.labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:c5f0696da23bc902297452',
  measurementId: 'G-VLDK64FJ6N',
};

function App() {
  initI18next();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div className="App" style={{ whiteSpace: 'pre-line' }}>
        <AllReactFireProviders />
      </div>
    </FirebaseAppProvider>
  );
}

export default App;
