import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function SnackBarError(props) {
  const {
    openError, setopenError, severity, errorMessage, errorTitle,
  } = props;
  return (
    <Snackbar
      open={openError}
      style={{ marginTop: 200 }}
      autoHideDuration={3000}
      onClose={() => {
        setopenError(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        style={{ padding: 50 }}
        severity={severity || 'error'}
      >
        {errorTitle && <AlertTitle>{errorTitle}</AlertTitle>}
        {errorMessage}
      </Alert>
    </Snackbar>
  );
}
