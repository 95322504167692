/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
// #TODO need to remove and fix this rule
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CompartmentForm from './CompartmentForm';
import { nominalVolumeFromCompartment } from '../utils';

export default function SmallScreenCompartments(props) {
  const { compartments, forms } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {compartments.map((cpt) => (
        <SmallScreenCompartment
          key={cpt.id}
          compartment={cpt}
          form={forms.find((form) => form.number === cpt.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      ))}
    </Box>
  );
}

function SmallScreenCompartment(props) {
  const { t } = useTranslation();
  const nominalVolume = nominalVolumeFromCompartment(props.compartment, t);
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        p: 1.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          <b>
            {t('operation.compartment')}
            :
            {props.compartment.id}
          </b>
        </Typography>
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          gutterBottom
          component="div"
        >
          {t('operation.nominal_volume')}
          :
          {nominalVolume}
        </Typography>
      </Box>
      <SmallScreenHeaders />
      <CompartmentForm
        layoutStyle={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          gap: 10,
        }}
        inputStyle={{ flexBasis: '50%' }}
        {...props}
      />
    </Paper>
  );
}

function SmallScreenHeaders() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: 1,
      }}
    >
      <Box sx={{ width: '50%' }}>
        <Typography variant="body2" gutterBottom>
          {t('operation.height')}
          :
        </Typography>
      </Box>
      <Box sx={{ width: '50%' }}>
        <Typography variant="body2" gutterBottom>
          {t('operation.volume')}
          :
        </Typography>
      </Box>
    </Box>
  );
}
