import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function SuccessDialog(props) {
  const { successOpen, handleSuccessClose } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={successOpen}
      onClose={handleSuccessClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <CheckCircleOutlineIcon sx={{ mr: 1, color: '#99bd5b' }} />
        {t('tank_creation.create_success')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('tank_creation.create_success_text')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSuccessClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
