import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import FileDropzone from 'components/FileDropzone';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import Papa from 'papaparse';
import useGlobal from 'global-state/store';
import modelDataFr from '../../../../staticFiles/modele-donnees.csv';

export default function TankGaugingDataDropZone(props) {
  const {
    formik, errorHandler, aborthandler,
  } = props;
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  function getExample() {
    return modelDataFr;
  }

  const callback = (acceptedFiles, setfileName) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onerror = errorHandler;
    reader.onabort = aborthandler;
    reader.onload = (e) => {
      // Only parsing the first line just to get the headers
      const parsedCsv = Papa.parse(reader.result, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        preview: 1,
        delimitersToGuess: [';', ','],
      });
      // #TODO handling english file format and headers
      if (!parsedCsv.meta.fields.includes('hauteur')) {
        // #TODO localized error handling
        globalActions.setSnackbarMessage({ message: "L'entête hauteur est manquant", severity: 'error' });
        return;
      }
      formik.setFieldValue(
        'nominalVolumesNamesFromData',
        parsedCsv.meta.fields.filter((name) => name !== 'hauteur'),
      );
      formik.setFieldValue('compartmentsData', reader.result);
      setfileName(file.path);
    };
    reader.readAsText(file);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        mt: 5,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('tank_creation.tank_data_dropzone')}
      </Typography>
      <FileDropzone
        text={t('tank_creation.upload_csv_text')}
        callback={callback}
        accept={{ 'text/csv': ['.csv'] }}
      />
      {formik.errors.compartmentsData && (<Typography sx={{ color: 'red' }}>{t('validations.required')}</Typography>)}
      <a href={getExample()} style={{ textDecoration: 'none' }} download>
        <Button
          variant="outlined"
          component="span"
          startIcon={<DescriptionIcon />}
        >
          {t('menu_boxettes.download_model_CSV')}
        </Button>
      </a>
    </Box>
  );
}
