import React, {
  useEffect, useState,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import {
  limit, collection, query, getDoc, doc,
} from 'firebase/firestore';
import { useUser, useFirestore, useFirestoreCollectionData } from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import { v4 as uuidv4 } from 'uuid';
import CertificateDetail from './CertificateDetail';
import CertificateListHeaders from './CertificateListHeaders';
import CertificateListItem from './CertificateListItem';

export default function CertificatesOrProgress() {
  const [globalState] = useGlobal();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_certificates', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  if (!globalState.activeOrganization) {
    return <CircularProgress sx={{ width: '100%', alignSelf: 'center' }} />;
  }
  return <Certificates />;
}

function Certificates() {
  const { t } = useTranslation();

  const [globalState] = useGlobal();
  const firestore = useFirestore();

  const tanksRef = collection(firestore, `organizations/${globalState.activeOrganization}`
  + '/apps/digitank-transporter/tanks');
  const tanksQuery = query(
    tanksRef,
    limit(2000),
  );
  const tanks = useFirestoreCollectionData(tanksQuery, { idField: 'id' });

  const [gaugeCertificates, setGaugeCertificates] = useState([]);
  const [loadingCertificates, setLoadingCertificates] = useState(true);

  useEffect(() => {
    if (tanks.status === 'success') {
      const fetchCertificates = async () => {
        const certificatePromises = tanks.data.map((tank) => {
          const certRef = doc(firestore, `organizations/${globalState.activeOrganization}`
          + `/apps/digitank-transporter/tanks/${tank.id}/gaugeCertificates/${tank.activeCertificate}`);
          return getDoc(certRef).then((certSnapshot) => ({
            tank,
            certificate: certSnapshot.exists ? { ...certSnapshot.data(), id: certSnapshot.id } : null,
          }));
        });
        const results = await Promise.all(certificatePromises);
        const fetchedCertificates = results
          .filter((result) => result.certificate !== null)
          .map((result) => result);

        fetchedCertificates.sort((a, b) => (a.certificate.expirationDate < b.certificate.expirationDate ? -1 : 1));

        setGaugeCertificates(fetchedCertificates);
        setLoadingCertificates(false);
      };

      fetchCertificates();
    }
  }, [tanks, firestore, globalState.activeOrganization]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: 2, mb: { xs: 8, md: 1 },
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.certificates')}
      </Typography>
      {loadingCertificates === true && (
        <CircularProgress sx={{ width: '100%', alignSelf: 'center' }} />
      )}
      {loadingCertificates === false && (
        <CertificateList certificates={gaugeCertificates} />
      )}
    </Box>
  );
}

function CertificateList({
  certificates,
}) {
  const [activeCertificate, setActiveCertificate] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = (certificate) => {
    setActiveCertificate(certificate);
    setOpen(true);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <CertificateListHeaders />
      <List
        dense
        sx={{
          width: '100%', border: 2, p: 0, borderRadius: 1, borderColor: 'white',
        }}
      >
        {certificates.map((certificate, i) => (
          <div key={uuidv4()}>
            <CertificateListItem
              index={i}
              alternateBackground={i % 2 === 0}
              certificate={certificate.certificate}
              handleOpen={() => handleOpen(certificate)}
            />
          </div>
        ))}
      </List>
      <CertificateDetail
        open={open}
        setOpen={setOpen}
        certificate={activeCertificate}
      />
    </Box>
  );
}
