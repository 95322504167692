const mask = {
  hL: 2,
  celsius: 2,
  fahrenheit: 2,
  TAV: 2,
  'g / L': 2,
  'mg / L': 0,
};

module.exports = mask;
