import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useFirestore, useUser } from 'reactfire';
import { getAnalytics } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import {
  storeClearOperationCpts,
  storeSaveOperation,
  storeOperationIdFromPath,
} from './compartmentStore';
import saveOperationToFirestore from './firestoreSaveOperation';

export default function FirestoreSaveConfirm(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const db = useFirestore();
  const { data: user } = useUser();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();
  const {
    saveOpen,
    setSaveOpen,
    successOpen,
    setSuccessOpen,
    forms,
    certificatePath,
    tankNumber,
    transporter,
    transporterName,
    totalVolume,
    volumeConversionResults,
  } = props;

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    navigate('/');
  };

  const handleSaveClose = () => {
    setSaveOpen(false);
  };

  function storeOperation() {
    const operationStoreId = storeOperationIdFromPath(certificatePath);
    storeSaveOperation(
      localStorage,
      operationStoreId,
      forms,
    );
  }

  const handleSaveOkAndStore = () => {
    // No need to await here the setting of doc especially because that make the feature work offline
    saveOperationToFirestore(
      db,
      `organizations/${globalState.activeOrganization}/apps/digitank-tanker-trucks`,
      analytics,
      user,
      tankNumber,
      certificatePath,
      transporter,
      transporterName,
      forms,
      totalVolume,
      volumeConversionResults,
      globalState.volumeConversionMethod,
    );
    storeClearOperationCpts(sessionStorage);
    storeOperation();
    setSaveOpen(false);
    setSuccessOpen(true);
  };

  return (
    <>
      <Dialog
        open={saveOpen}
        onClose={handleSaveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('operation.save_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('operation.save_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            onClick={handleSaveClose}
            color="secondary"
          >
            {t('no')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleSaveOkAndStore}
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successOpen}
        onClose={handleSuccessClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <CheckCircleOutlineIcon sx={{ mr: 1, color: '#99bd5b' }} />
          {t('operation.save_success')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {globalState.networkState !== 'offline'
              ? (t('operation.save_success_text'))
              : (t('operation.offline_save_warning'))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" variant="contained" onClick={handleSuccessClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
