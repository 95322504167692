import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

export default function GaugingCurve(props) {
  const { t } = useTranslation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  );

  const options = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: t('tank_creation.volume_and_unit'),
        },
      },
      x: {
        title: {
          display: true,
          text: t('tank_creation.height_and_unit'),
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const { allHeights, compartmentsDictionaries } = props;
  const colors = ['#437b89', '#f4ae43', '#99bd5b', '#000000', '#4d90fe', '#49d555', '#cb7272', '#d549c0', '#d5cf49'];
  const minAllHeights = Math.min(...compartmentsDictionaries.map((comp) => comp.minHeight));
  const maxAllHeights = Math.max(...compartmentsDictionaries.map((comp) => comp.maxHeight));
  const trimmedHeights = allHeights.filter((height) => (height >= minAllHeights && height <= maxAllHeights));

  const data = {
    labels: trimmedHeights,
    datasets: compartmentsDictionaries.map((compartment, i) => ({
      label: compartment.id,
      data: trimmedHeights.map((height) => compartment.heightToVolume[height]),
      borderColor: colors[i],
      backgroundColor: colors[i],
    })),
  };

  return (
    <Line options={options} data={data} />
  );
}
