import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {
  NumberInput,
  LabelInputSelect,
} from 'components/BoxetteInputs';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import useGlobal from 'global-state/store';
import { useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';
import alcoholUnitRelatedsFrom from 'components/units/UnitsRelatedFrom';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import VolumeConversionMethodInfo from 'components/volumeConversion/VolumeConversionMethodInfo';
import ValidationSchema from './ValidationSchema';
import decimalsMask from './DecimalsMask';

export default function PAVolumeConversionForm() {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const laboxAlcoholometryApiCall = httpsCallable(functions, 'laboxAlcoholometryApiCall2ndGen');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { paResults, deeplinkReturnId } = location.state;

  const [alcoholUnitRelateds] = React.useState(
    alcoholUnitRelatedsFrom(
      globalState.userUnits.alcoholUnit,
      globalState.userUnits.temperatureUnit,
    ),
  );

  async function submit(values) {
    setIsLoading(true);
    try {
      const tavRequest = {
        abvValue: values.measuredTav.replace(/,/, '.'),
        abvUnit: values.alcoholUnit,
        abvOutUnit: values.alcoholUnit,
        temperatureInValue: values.tavTemp.replace(/,/, '.'),
        temperatureOutValue: '20.0',
        temperatureInUnit: values.temperatureUnit,
        temperatureOutUnit: values.temperatureUnit,
        abvPrecision: decimalsMask[values.alcoholUnit],
        temperatureInPrecision: decimalsMask[values.temperatureUnit],
        intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
        traditionalMethod: globalState.volumeConversionMethod === 'traditional',
      };
      const tavResults = await laboxAlcoholometryApiCall({
        call: 'convertABVToTemperature',
        form: tavRequest,
        app: 'digitank-tanker-trucks',
      });
      const volumeRequest = {
        volumeAPAt20cValue: values.volumeValue.replace(/,/, '.'),
        temperatureValue: values.volumeTemperature.replace(/,/, '.'),
        abvAt20cValue: tavResults.data.result.ABVAt20c.value,
        volumeAPAt20cUnit: values.volumeUnit,
        temperatureUnit: values.temperatureUnit,
        abvAt20cUnit: values.alcoholUnit,
        volumeAPAt20cPrecision: decimalsMask[values.volumeUnit],
        temperaturePrecision: decimalsMask[values.temperatureUnit],
        abvAt20cPrecision: decimalsMask[values.volumeUnit],
        intermediateRounding: globalState.alcoApiConfig.intermediateRounding,
        traditionalMethod: globalState.volumeConversionMethod === 'traditional',
      };
      const volumeResults = await laboxAlcoholometryApiCall({
        call: 'getVolumeAtTempFromAbvAndPureAlcoholAtTemperature',
        form: volumeRequest,
        app: 'digitank-tanker-trucks',
      });
      const paResult = {
        tavConv: tavResults.data.result,
        paVolConv: volumeResults.data.result,
        values,
      };
      navigate(
        '../operation',
        {
          replace: true,
          state: { ...location.state, paResults: { ...paResults, [deeplinkReturnId]: paResult } },
        },
      );
    } catch (err) {
      let parsedError;
      try {
        parsedError = JSON.parse(err.message);
      } catch {
        globalActions.setSnackbarMessage({ message: err.message, severity: 'error' });
        return;
      }
      if (parsedError
      && (parsedError.name === 'IndexOutOfBoundsError'
      || parsedError.name === 'HeaderOutOfBoundsError'
      || parsedError.name === 'NotFoundError'
      || parsedError.name === 'ValueError'
      || parsedError.name === 'SolveError')) {
        globalActions.setSnackbarMessage({ message: t('error.handled_error_values'), severity: 'warning' });
      } else if (parsedError && parsedError.name === 'NotEnoughCredits') {
        globalActions.setSnackbarMessage({ message: t('menu_boxettes.error_credits'), severity: 'error' });
      } else {
        globalActions.setSnackbarMessage({ message: err.message, severity: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      volumeValue: '',
      volumeTemperature: '',
      volumeUnit: 'hL',
      temperatureUnit: 'celsius',
      measuredTav: '',
      tavTemp: '',
      alcoholUnit: 'TAV',
    },
    validationSchema: ValidationSchema(t, alcoholUnitRelateds),
    onSubmit(values) {
      return submit(values);
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 3,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 3,
            width: { xs: '100%', sm: 350 },
          }}
        >
          <LabelInputSelect
            label={(
              <Typography component="span">
                {t('forms.volume_PA_value')}
              </Typography>
                )}
            input={(
              <NumberInput
                onChange={formik.handleChange}
                name="volumeValue"
                placeholder="ex: 4.567"
                value={formik.values.volumeValue}
                decimalScale={decimalsMask[formik.values.volumeUnit]}
                onBlur={(e) => {
                  formik.handleBlur(e, formik.values.volumeUnit);
                }}
              />
                )}
            select={(
              <Typography component="span">
                HL
              </Typography>
                )}
            error={
                  formik.touched.volumeValue
                  && formik.errors.volumeValue && (
                    <Typography style={{ fontSize: 10, color: 'red' }}>
                      {formik.errors.volumeValue}
                    </Typography>
                  )
                }
          />

          <LabelInputSelect
            label={(
              <Typography component="span">
                {t('forms.simple_measured_temp')}
              </Typography>
                )}
            input={(
              <NumberInput
                onChange={formik.handleChange}
                name="volumeTemperature"
                placeholder="ex: 20"
                value={formik.values.volumeTemperature}
                decimalScale={decimalsMask[formik.values.temperatureUnit]}
                onBlur={(e) => {
                  formik.handleBlur(e, formik.values.temperatureUnit);
                }}
              />
                )}
            select={(
              <Typography component="span">
                °C
              </Typography>
                )}
            error={
                  formik.touched.volumeTemperature
                  && formik.errors.volumeTemperature && (
                    <Typography
                      component="span"
                      style={{ fontSize: 13, color: 'red' }}
                    >
                      {formik.errors.volumeTemperature}
                    </Typography>
                  )
                }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            gap: 3,
            width: { xs: '100%', sm: 350 },
          }}
        >
          <LabelInputSelect
            label={
              <Typography component="span">{t('forms.measured_tav')}</Typography>
        }
            input={(
              <NumberInput
                onChange={formik.handleChange}
                name="measuredTav"
                placeholder="ex: 56.88"
                value={formik.values.measuredTav}
                decimalScale={decimalsMask[formik.values.alcoholUnit]}
                onBlur={(e) => {
                  formik.handleBlur(e, formik.values.alcoholUnit);
                }}
              />
        )}
            error={formik.touched.measuredTav && formik.errors.measuredTav && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.measuredTav}
            </Typography>
            )}
            select={(
              <Typography component="span">
                % vol.
              </Typography>
        )}
          />

          <LabelInputSelect
            label={
              <Typography component="span">{t('forms.measured_temp')}</Typography>
        }
            input={(
              <NumberInput
                name="tavTemp"
                onChange={formik.handleChange}
                placeholder="ex: 12.2"
                value={formik.values.tavTemp}
                decimalScale={decimalsMask[formik.values.temperatureUnit]}
                onBlur={(e) => {
                  formik.handleBlur(e, formik.values.temperatureUnit);
                }}
                allowNegative
              />
        )}
            error={formik.touched.tavTemp && formik.errors.tavTemp && (
            <Typography component="span" style={{ fontSize: 13, color: 'red' }}>
              {formik.errors.tavTemp}
            </Typography>
            )}
            select={(
              <Typography component="span">
                °C
              </Typography>
        )}
          />
        </Box>
      </Box>
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={!formik.isValid}
      >
        {t('forms.calcul')}
      </LoadingButton>
      <VolumeConversionMethodInfo />
    </Box>
  );
}
