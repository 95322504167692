import * as yup from 'yup';

export default function validationSchema(t) {
  return yup.object().shape({
    batchNames: yup
      .array()
      .of(yup.object().shape({
        number: yup
          .string()
          .required(t('validations.required')),
      })),
  });
}
