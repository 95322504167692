import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { NumberInput } from 'components/Inputs';
import { dynamicUnitName } from 'components/units/UnitDefinitionsAndMasks';
import validationSchema from './validationSchema';
import SelectPAResult from './SelectPAResult';

export default function BatchForm(props) {
  const {
    batch, alcoholUnitRelateds, pureAlcoholResults, formikRef, paResultItems, handleChangeBatch,
  } = props;
  const { t } = useTranslation();
  const batchIndex = batch.number - 1;
  const [status, setStatus] = useState('initial');

  const initValues = () => {
    if (batchIndex < paResultItems.length) {
      const item = paResultItems[batchIndex];
      return {
        measuredTav: item.values.measuredTav,
        measuredTemp: item.values.volumeTemperature,
        measuredTAVTemp: item.values.tavTemp,
      };
    }
    return {
      measuredTav: batch.tav,
      measuredTemp: batch.temperature,
      measuredTAVTemp: batch.tavTemperature,
    };
  };

  const formik = useFormik({
    initialValues: initValues(),
    validationSchema: validationSchema(t, alcoholUnitRelateds),
    onSubmit(values) {
      return handleChangeBatch(batch.number, values);
    },
  });

  useEffect(() => {
    formikRef(formik);
  }, [formik, formikRef]);

  useEffect(() => {
    if (status === 'initial') {
      setStatus('done');
      if (formikRef) {
        formikRef(formik);
      }
    }
  }, [formik, formikRef, status]);

  const handleTryToSubmit = () => {
    if (formik.dirty && formik.isValid) {
      formik.handleSubmit();
    }
  };

  const handleKeyPressed = (e) => {
    const key = e.key ? e.key : e.code;
    if (['Enter', 'NumpadEnter'].includes(key)) {
      handleTryToSubmit();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper sx={{
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderStyle: 'solid',
        borderWidth: 'medium',
        borderColor: batch.color,
        width: '80vw',
        maxWidth: 800,
        p: 2,
        pb: 3,
      }}
      >
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
        }}
        >
          <Typography component="span" sx={{ fontWeight: 'bold', color: batch.color }}>
            {t('volume_conversion.batch_number')}
            {batch.number}
          </Typography>
          {Object.entries(pureAlcoholResults).length > 0 && (
            <SelectPAResult
              formik={formik}
              pureAlcoholResults={pureAlcoholResults}
              batchIndex={batchIndex}
            />
          )}
        </Box>

        <Typography component="span">
          {t('operation.compartments')}
          :
          {' '}
          {batch.compartmentIds.join(', ')}
        </Typography>
        <Box sx={{
          display: 'flex',
          gap: 1,
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
        >
          <LabelInput
            label={t('volume_conversion.measured_temp', { unit: dynamicUnitName(alcoholUnitRelateds.tempUnit) })}
            input={(
              <NumberInput
                stretch="true"
                name="measuredTemp"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleTryToSubmit();
                }}
                onKeyPress={handleKeyPressed}
                error={false}
                onChange={formik.handleChange}
                placeholder="ex: 12.2"
                value={formik.values.measuredTemp}
              />
            )}
            error={formik.touched.measuredTemp
              && formik.errors.measuredTemp && (
                <Typography component="span" style={{ fontSize: 13, color: 'red', maxWidth: 200 }}>
                  {formik.errors.measuredTemp}
                </Typography>
            )}
          />
          <LabelInput
            label={t('volume_conversion.measured_tav', { unit: dynamicUnitName(alcoholUnitRelateds.alcoholUnit) })}
            input={(
              <NumberInput
                stretch="true"
                onChange={formik.handleChange}
                onKeyPress={handleKeyPressed}
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleTryToSubmit();
                }}
                name="measuredTav"
                placeholder="ex: 56.88"
                value={formik.values.measuredTav}
              />
            )}
            error={formik.errors.measuredTav
              && formik.touched.measuredTav && (
                <Typography component="span" style={{ fontSize: 13, color: 'red', maxWidth: 200 }}>
                  {formik.errors.measuredTav}
                </Typography>
            )}
          />

          <LabelInput
            label={t('volume_conversion.measured_tav_temp', { unit: dynamicUnitName(alcoholUnitRelateds.tempUnit) })}
            input={(
              <NumberInput
                stretch="true"
                name="measuredTAVTemp"
                onBlur={(e) => {
                  formik.handleBlur(e);
                  handleTryToSubmit();
                }}
                onKeyPress={handleKeyPressed}
                error={false}
                onChange={formik.handleChange}
                placeholder="ex: 12.2"
                value={formik.values.measuredTAVTemp}
              />
            )}
            error={formik.touched.measuredTAVTemp
              && formik.errors.measuredTAVTemp && (
                <Typography component="span" style={{ fontSize: 13, color: 'red', maxWidth: 280 }}>
                  {formik.errors.measuredTAVTemp}
                </Typography>
            )}
          />
        </Box>
      </Paper>
    </form>
  );
}

function LabelInput(props) {
  const { label, input, error } = props;

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexGrow: 1,
      maxWidth: 400,
    }}
    >
      <Typography component="span">
        {label}
      </Typography>
      {input}
      {error}
    </Box>
  );
}
