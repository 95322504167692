/* eslint-disable react/jsx-props-no-spreading */
// #TODO need to take time to remove the rules above and fix
import * as React from 'react';
import { getWindowDimensions } from '../utils';
import SmallScreenCompartments from './SmallScreenCompartments';
import LargeScreenCompartments from './LargeScreenCompartments';

export default function CompartmentsTable(props) {
  const [isSmallScreen, setIsSmallScreen] = React.useState(
    getWindowDimensions().width < 600,
  );

  React.useEffect(() => {
    function handleResize() {
      setIsSmallScreen(getWindowDimensions().width < 600);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isSmallScreen) {
    return (
      <SmallScreenCompartments
        {...props}
      />
    );
  }
  return (
    <LargeScreenCompartments
      {...props}
    />
  );
}
