import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useFirestore, useUser, useFunctions } from 'reactfire';
import { useFormik } from 'formik';
import { httpsCallable } from 'firebase/functions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Fab from '@mui/material/Fab';
import Help from '@mui/icons-material/QuestionMark';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useGlobal from 'global-state/store';
import { useNavigate, useLocation } from 'react-router-dom';
import TankCertificateDropZone from './TankCertificateDropZone';
import TankInfoDropZone from './TankInfoDropZone';
import TankGaugingDataDropZone from './TankGaugingDataDropZone';
import NominalVolumesList from './NominalVolumesList';
import TankInfoInputs from './TankInfoInputs';
import SuccessDialog from './SuccessDialog';
import {
  tankAlreadyExists,
} from './firestoreRequests';
import GaugingDataValidation from './GaugingDataValidation';
import validationSchema from './validationSchema';

export default function TankCreationForm(props) {
  const { t } = useTranslation();
  const { transporters, update = false } = props;
  const [globalState, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();
  const { data: user } = useUser();
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [openWarningProceed, setopenWarningProceed] = React.useState(false);
  const [contentWarningProceed, setcontentWarningProceed] = React.useState(undefined);
  const [warningProceedCallback, setwarningProceedCallback] = React.useState(
    () => {},
  );
  const [openInfo, setopenInfo] = React.useState(false);
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const ttAdminCreateOrUpdateTankCall = httpsCallable(functions, 'ttAdminCreateOrUpdateTankCallM2');
  const [uploading, setuploading] = React.useState(false);
  const acceptedHeightUnit = ['m'];
  const acceptedVolumeUnit = ['m3'];
  const location = useLocation();
  const navigate = useNavigate();

  const initialFormValues = () => {
    if (update) {
      return {
        transporter: location.state.transporter,
        certificateNumber: '',
        tankId: location.state.tankId,
        provider: '',
        heightUnit: 'm',
        volumeUnit: 'm3',
        capacity: location.state.capacity,
        expirationDate: '',
        gaugeDate: '',
        nominalVolumesFromInfo: {},
        nominalVolumesNamesFromData: [],
        nominalVolumes: {},
        compartmentsData: '',
        originalPdfCertificate: {},
        organizationId: globalState.activeOrganization,
      };
    }
    return {
      transporter: transporters[0],
      certificateNumber: '',
      tankId: '',
      provider: '',
      heightUnit: 'm',
      volumeUnit: 'm3',
      capacity: '',
      expirationDate: '',
      gaugeDate: '',
      nominalVolumesFromInfo: {},
      nominalVolumesNamesFromData: [],
      nominalVolumes: {},
      compartmentsData: '',
      originalPdfCertificate: {},
      organizationId: globalState.activeOrganization,
    };
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
    setopenWarningProceed(false);
    if (update) {
      navigate(-1);
    } else {
      window.location.reload();
    }
  };

  const handleWarningProceed = (content, callback) => {
    setcontentWarningProceed(content);
    setwarningProceedCallback(() => callback);
    setopenWarningProceed(true);
  };

  const formik = useFormik({
    initialValues: initialFormValues(),
    validationSchema: validationSchema(t, transporters, acceptedHeightUnit, acceptedVolumeUnit),
    onSubmit(values) {
      return submit(values);
    },
  });

  async function upload(values) {
    try {
      setuploading(true);
      logEvent(analytics, 'admin_add_tank_call', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
      await ttAdminCreateOrUpdateTankCall(values);
      setSuccessOpen(true);
    } catch (error) {
      if (error.message === 'INTERNAL') {
        globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
      } else {
        globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
      }
      logEvent(analytics, 'error_admin_add_tank_call', {
        user_uid: user?.uid,
        error_message: error.message,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    } finally {
      setuploading(false);
    }
  }

  async function compartmentsReviewAndUpload(values) {
    const compartmentsReviewContent = (
      <GaugingDataValidation
        tankId={formik.values.tankId}
        data={formik.values.compartmentsData}
      />
    );
    handleWarningProceed(compartmentsReviewContent, async () => upload(values));
  }

  async function checkTankIdAndUpload(values) {
    setopenWarningProceed(false);
    const docExistsAlready = await tankAlreadyExists(
      db,
      `organizations/${globalState.activeOrganization}/apps/digitank-tanker-trucks`,
      values.transporter,
      values.tankId,
    );
    if (docExistsAlready && !update) {
      globalActions.setSnackbarMessage({ message: t('tank_creation.tank_already_exists'), severity: 'error' });
    } else {
      await compartmentsReviewAndUpload(values);
    }
  }

  async function submit(values) {
    const missingThingsMessages = [];
    if (areMissingNominalVolumes(values.nominalVolumes)) {
      missingThingsMessages.push(
        t('tank_creation.warning_missing_nominal_volumes'),
      );
    }
    if (isMissingPdf(values.originalPdfCertificate)) {
      missingThingsMessages.push(t('tank_creation.warning_missing_pdf'));
    }
    if (missingThingsMessages.length > 0) {
      missingThingsMessages.push(t('tank_creation.warning_continue'));
      const message = TypoComponent(missingThingsMessages.join('\n\n'));
      handleWarningProceed(message, async () => {
        await checkTankIdAndUpload(values);
      });
    } else {
      await checkTankIdAndUpload(values);
    }
  }

  function areMissingNominalVolumes(nominalVolumes) {
    return Object.values(nominalVolumes).some(
      (value) => value === '' || value === undefined,
    );
  }

  function isMissingPdf(originalPdf) {
    return (
      originalPdf === undefined || Object.entries(originalPdf).length === 0
    );
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        gap: 20,
      }}
    >
      <Typography variant="h5" component="div" sx={{ mt: 2 }}>
        {t('tank_creation.add_tank')}
      </Typography>
      <TankInfoDropZone
        update={update}
        formik={formik}
        transporters={transporters}
        acceptedHeightUnit={acceptedHeightUnit}
        acceptedVolumeUnit={acceptedVolumeUnit}
      />

      <TankInfoInputs
        formik={formik}
        transporters={transporters}
        acceptedHeightUnit={acceptedHeightUnit}
        acceptedVolumeUnit={acceptedVolumeUnit}
        update={update}
      />

      <TankGaugingDataDropZone formik={formik} />
      <NominalVolumesList formik={formik} />
      <TankCertificateDropZone formik={formik} />
      <Button
        sx={{
          maxWidth: 200, alignSelf: 'center', mt: 5, mb: 5,
        }}
        variant="contained"
        type="submit"
        size="large"
        disabled={formik.isSubmitting || !formik.isValid}
        endIcon={<CloudUpload />}
      >
        {t('tank_creation.upload_csv')}
      </Button>
      <SuccessDialog
        successOpen={successOpen}
        handleSuccessClose={handleSuccessClose}
      />
      <Fab
        color="primary"
        aria-label="download"
        sx={{ position: 'fixed', bottom: '5%', right: '5%' }}
        onClick={() => setopenInfo(true)}
      >
        <Help />
      </Fab>
      <InfoDialog openInfo={openInfo} setopenInfo={setopenInfo} />
      <WarningProceedDialog
        openWarningProceed={openWarningProceed}
        setopenWarningProceed={setopenWarningProceed}
        contentWarningProceed={contentWarningProceed}
        warningProceedCallback={warningProceedCallback}
        uploading={uploading}
      />
    </form>
  );
}

function InfoDialog(props) {
  const { t } = useTranslation();
  const { setopenInfo, openInfo } = props;

  const handleClose = () => {
    setopenInfo(false);
  };

  return (
    <Dialog
      open={openInfo}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('tank_creation.add_tank')}
      </DialogTitle>
      <DialogContent>
        <Typography component="p" sx={{ whiteSpace: 'pre-line' }}>
          {t('tank_creation.tank_creation_info', { joinArrays: '' })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function WarningProceedDialog(props) {
  const { t } = useTranslation();

  const {
    openWarningProceed,
    setopenWarningProceed,
    contentWarningProceed,
    warningProceedCallback,
    uploading,
  } = props;

  const handleClose = () => {
    if (!uploading) {
      setopenWarningProceed(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openWarningProceed}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>{contentWarningProceed}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('no')}
        </Button>
        <LoadingButton loading={uploading} variant="outlined" onClick={warningProceedCallback}>
          {t('yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function TypoComponent(message) {
  return (
    <Typography component="p" sx={{ whiteSpace: 'pre-line' }}>
      {message}
    </Typography>
  );
}
