import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import {
  doc,
  where,
  getDoc,
} from 'firebase/firestore';
import { useFirestore, useUser } from 'reactfire';
import Papa from 'papaparse';
import CircularProgress from '@mui/material/CircularProgress';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Filters from 'components/admin/managingTanks/TankFilters';
import OurPagination from 'components/OurPagination';
import { downloadCsvFile } from '../../utils';
import OperationDetailDialog from './OperationDetailDialog';
import OperationListItem from './OperationListItem';

export default function Operations() {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const analytics = getAnalytics();
  const [globalState] = useGlobal();

  const computeListOfConditions = useCallback((formikValues) => {
    let conditions = [
      where('dateAndTime', '<=', formikValues.endDate),
      where('dateAndTime', '>=', formikValues.startDate),
    ];
    if (formikValues.transporterName !== '') {
      conditions = [...conditions, where('transporterName', '==', formikValues.transporterName)];
    }
    return conditions;
  }, []);

  const formikInitValues = useMemo(() => ({
    transporterName: '',
    startDate: initialStartDate(),
    endDate: initialEndDate(),
  }), []);

  const [listOfConditions, setListOfConditions] = useState(computeListOfConditions(formikInitValues));

  function initialStartDate() {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d;
  }

  function initialEndDate() {
    const d = new Date();
    d.setHours(23, 59, 0, 0); // next before midnight
    return d;
  }

  useEffect(() => {
    if (globalState.activeOrganization !== '' && user?.uid) {
      logEvent(analytics, 'loading_operations', {
        user_uid: user?.uid,
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
    }
  }, [analytics, globalState.activeOrganization, user?.uid]);

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', width: '100%',
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2, mt: 2 }}>
        {t('admin.operations')}
      </Typography>
      {globalState.activeOrganization !== '' && (
        <OurPagination
          collectionPath={`organizations/${globalState.activeOrganization}/apps/digitank-tanker-trucks/operations`}
          listOfConditions={listOfConditions}
          orderByAttribute="dateAndTime"
          filters={(
            <OperationListFilters
              formikInitValues={formikInitValues}
              setListOfConditions={setListOfConditions}
              computeListOfConditions={computeListOfConditions}
            />
          )}
          list={(
            <OperationList />
          )}
        />
      )}
      {globalState.activeOrganization === '' && <CircularProgress />}
    </Box>
  );
}

function OperationListFilters({
  initSearch, formikInitValues, setListOfConditions, computeListOfConditions,
}) {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    transporterName: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: formikInitValues,
    validationSchema,
    onSubmit: (values) => {
      const newConditions = computeListOfConditions(values);
      setListOfConditions(newConditions);
      initSearch(newConditions);
    },
  });

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%',
    }}
    >
      <Filters formik={formik} datePickersLabel={t('admin.made')} />
    </Box>
  );
}

function OperationList({ docs }) {
  const { t } = useTranslation();
  const db = useFirestore();
  const [operationsCsv, setoperationsCsv] = useState('');
  const [activeOperation, setactiveOperation] = useState('');
  const [organizationNames, setorganizationNames] = useState([]);
  const [open, setOpen] = useState(false);

  const getOrganizationName = useCallback(async (organizationId) => {
    const orgRef = doc(db, `organizations/${organizationId}`);
    const orgDoc = await getDoc(orgRef);
    return orgDoc.data().name;
  }, [db]);

  const fetchAllOrganizationNames = useCallback(async (organizationIds) => {
    const organizationNamePromises = organizationIds.map(async (id) => ({
      id,
      name: await getOrganizationName(id),
    }));
    const neworganizationNames = await Promise.all(organizationNamePromises);
    const organizationDict = neworganizationNames.reduce((acc, org) => {
      acc[org.id] = org.name;
      return acc;
    }, {});
    return organizationDict;
  }, [getOrganizationName]);

  const formatOperationsForCsv = useCallback((newOperations, organizationDict) => newOperations.map((opDoc) => {
    const op = opDoc.data();
    return {
      id: op.id,
      [t('operation_csv_headers.tankNumber')]: op.tankNumber,
      date: new Date(op.dateAndTime.toMillis()).toLocaleString(),
      [t('operation_csv_headers.transporter')]:
        `${op.transporterName}(${organizationDict[op.organizationTransporter]})`,
      [t('operation_csv_headers.certificateNumber')]: op.certificateNumber,
      [t('operation_csv_headers.totalVolume')]: op.totalVolume,
    };
  }), [t]);

  const refresh = useCallback(async () => {
    const orgIds = docs.map((o) => o.data().organizationTransporter);
    const orgIdsSet = new Set([...orgIds.values()]);
    const newOrganizationNames = await fetchAllOrganizationNames([...orgIdsSet]);
    setorganizationNames(newOrganizationNames);

    const operationListCsv = Papa.unparse(
      formatOperationsForCsv(docs, newOrganizationNames),
      {
        delimiter: ';',
      },
    );
    setoperationsCsv(operationListCsv);
  }, [docs, fetchAllOrganizationNames, formatOperationsForCsv]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleOpen = (operation) => {
    setactiveOperation(operation);
    setOpen(true);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', gap: 1,
    }}
    >
      <ListHeader />
      <Paper elevation={0} sx={{ p: 1 }}>
        <List dense sx={{ width: '100%' }}>
          {docs.map((op, i) => (
            <div key={op.id}>
              <OperationListItem
                organizationName={organizationNames[op.data().organizationTransporter]}
                operation={op.data()}
                index={i}
                handleOpen={handleOpen}
              />
              <Divider />
            </div>
          ))}
        </List>
        {' '}

      </Paper>
      <OperationDetailDialog
        organizationName={organizationNames[activeOperation.organizationTransporter]}
        operation={activeOperation}
        open={open}
        setOpen={setOpen}
      />
      <Fab
        color="primary"
        aria-label="download"
        size="large"
        sx={{ position: 'fixed', bottom: '5%', right: '5%' }}
        onClick={(e) => {
          downloadCsvFile('operations', operationsCsv);
        }}
      >
        <DownloadIcon />
      </Fab>
    </Box>
  );
}

function ListHeader() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        px: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        width: '100%',
      }}
    >
      <Typography>{t('admin.tank_id')}</Typography>
      <Typography>{t('admin.transporter')}</Typography>
      <Typography sx={{ width: 155 }}>{t('admin.date')}</Typography>
    </Box>
  );
}
