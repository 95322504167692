import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import {
  doc, getDoc, collection, query, orderBy, limit, where, getDocs,
} from 'firebase/firestore';
import TankCard from 'components/operation/TankCard';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useGlobal from 'global-state/store';
import useMenu from 'menu-actions/useMenu';
import Compartments from './Compartments';
import { storeSetCptsIds } from './compartmentStore';

export default function Operation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { tankPath } = location.state || {};

  useEffect(() => {
    if (!tankPath) {
      navigate('/');
    }
  }, [navigate, tankPath]);

  if (tankPath) {
    return <OperationContent tankPath={tankPath} />;
  }
  return null;
}

function OperationContent({ tankPath }) {
  const analytics = getAnalytics();
  const [loadingState, setLoadingState] = useState('notLoaded');
  const { t } = useTranslation();
  const { dispatchMenuActions } = useMenu();
  const navigate = useNavigate();
  const tankPathElements = tankPath.toString().split('/');
  const transporterId = tankPathElements[1];
  const tankId = tankPathElements[5];

  const db = useFirestore();
  const [globalState] = useGlobal();
  const [certificatePath, setCertificatePath] = useState('');
  const [validation, setValidation] = useState({ hasBeenChecked: false });
  const tankAndCertif = useMemo(() => ({
    isLoaded: false,
    tankPath,
    certificate: undefined,
    tank: undefined,
  }), [tankPath]);

  const verify = (newValidation) => {
    setValidation({ ...newValidation, hasBeenChecked: newValidation.status !== 'reset' });
  };

  useEffect(() => {
    if (globalState.userStatus === 'notYetSetup') {
      navigate('/');
    }
  });

  useEffect(() => {
    dispatchMenuActions({ type: 'clear' });
  }, [dispatchMenuActions]);

  useEffect(() => {
    const tankRef = doc(db, tankPath);

    async function getTankAndCertificate() {
      const tankDoc = await getDoc(tankRef);
      const tankData = tankDoc.data();
      tankAndCertif.tank = tankData;
      getCertificate(
        tankDoc.ref.firestore,
        tankDoc.ref.path,
        tankData.activeCertificate,
      );
    }
    async function getMostRecentValidation(certificateNumber) {
      const validationsRef = collection(
        db,
        `organizations/${globalState.activeOrganization}/apps/digitank-tanker-trucks/validations`,
      );

      const validationQuery = query(
        validationsRef,
        where('certificateNumber', '==', certificateNumber),
        where('organizationTransporter', '==', transporterId),
        where('tankNumber', '==', tankId),
        orderBy('dateAndTime', 'desc'),
        limit(1),
      );

      const querySnapshot = await getDocs(validationQuery);

      if (!querySnapshot.empty) {
        const mostRecentValidation = querySnapshot.docs[0].data();
        return mostRecentValidation;
      }
      return null;
    }

    async function getCertificate(firestore, path, certifId) {
      const certifRef = doc(db, path, 'gaugeCertificates', certifId);
      setCertificatePath(certifRef.path);
      try {
        const certifDoc = await getDoc(certifRef);
        const certifData = certifDoc.data();
        const validationData = await getMostRecentValidation(certifRef.id);
        if (!validationData) {
          setValidation({ hasBeenChecked: false });
        } else {
          verify(validationData);
        }

        tankAndCertif.certificate = certifData;
        storeSetCptsIds(sessionStorage, certifData.compartments.map((c) => c.id));
        tankAndCertif.isLoaded = true;
        setLoadingState('loaded');
      } catch (err) {
        if (globalState.networkState === 'offline') {
          setLoadingState('error');
        } else {
          throw err;
        }
      }
    }

    if (loadingState === 'notLoaded' && globalState.activeOrganization !== '') {
      setLoadingState('loading');
      logEvent(analytics, 'operation_page', {
        appName: 'Digitank, Tanker Trucks',
        organization: globalState.activeOrganization,
      });
      getTankAndCertificate();
    }
  }, [db, tankAndCertif, tankAndCertif.isLoaded, analytics, loadingState,
    globalState.activeOrganization, transporterId, tankId, tankPath, globalState.networkState]);

  if (loadingState === 'loaded') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 3,
          gap: 2,
        }}
      >

        <TankCard
          setLoadingState={setLoadingState}
          tankPath={tankPath}
          tankNumber={tankId}
          transporterName={tankAndCertif.tank.transporterName}
          certificate={tankAndCertif.certificate}
          validation={validation}
          verify={verify}
          dispatchMenuActions={dispatchMenuActions}
        />

        <Compartments
          tankPath={tankPath}
          compartments={tankAndCertif.certificate.compartments}
          tankNumber={tankId}
          certificatePath={certificatePath}
          transporter={transporterId}
          transporterName={tankAndCertif.tank.transporterName}
        />
      </Box>
    );
  } if (loadingState === 'error') {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
        gap: 2,
      }}
      >

        <Typography
          component="span"
          sx={{ maxWidth: 800 }}
        >
          {t('tank_selection.offline_tank_fetch_error')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          endIcon={<ReplayIcon />}
        >
          {t('reload')}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
