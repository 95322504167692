import * as yup from 'yup';
import { roundNoFixed } from '../utils';

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default function validationSchema(
  t,
  minHeight,
  maxHeight,
  minVolume,
  maxVolume,
) {
  const minHeightRounded = roundNoFixed(minHeight, 4);
  const maxHeightRounded = roundNoFixed(maxHeight, 4);
  const minVolumeRounded = roundNoFixed(minVolume, 4);
  const maxVolumeRounded = roundNoFixed(maxVolume, 4);

  const validationPhrases = {
    heightValidationPhrase: t('validations.height', {
      minHeight: minHeightRounded,
      maxHeight: maxHeightRounded,
    }),
    volumeValidationPhrase: t('validations.volume', {
      minVolume: minVolumeRounded,
      maxVolume: maxVolumeRounded,
    }),
  };

  return yup.object().shape({
    height: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(true)
      .typeError(validationPhrases.heightValidationPhrase)
      .min(minHeightRounded, validationPhrases.heightValidationPhrase)
      .max(maxHeightRounded, validationPhrases.heightValidationPhrase),
    volume: yup
      .number()
      .transform(emptyStringToNull)
      .nullable(true)
      .typeError(validationPhrases.volumeValidationPhrase)
      .min(minVolumeRounded, validationPhrases.volumeValidationPhrase)
      .max(maxVolumeRounded, validationPhrases.volumeValidationPhrase),
  });
}
