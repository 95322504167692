import * as React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import useGlobal from 'global-state/store';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';

export default function VolumeConversionMethodInfo(props) {
  const { t } = useTranslation();
  const [globalState] = useGlobal();
  const [openInfo, setopenInfo] = React.useState(false);

  return (

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => setopenInfo(true)}
    >
      <Typography component="p" variant="caption" sx={{ whiteSpace: 'pre-line' }}>
        {t('settings.volume_calculation_method')}
        {': '}
        {globalState.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method'))}
        {globalState.volumeConversionMethod !== 'traditional'
         && ('OIML-OIV')}
      </Typography>
      <IconButton
        color="primary"
        sx={{ flexShrink: 0 }}
      >
        <Info />
      </IconButton>
      <Snackbar
        open={openInfo}
        autoHideDuration={15000}
        onClose={() => setopenInfo(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          sx={{
            p: 3,
            backgroundColor: 'white',
          }}
          severity="info"
        >
          <Typography component="p" sx={{ whiteSpace: 'pre-line', textAlign: 'left', width: '100%' }}>
            {globalState.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method_info', { joinArrays: '\n' }))}
            {globalState.volumeConversionMethod !== 'traditional'
         && (t('settings.oiml_oiv_method_info', { joinArrays: '\n' }))}
            {'\n\n'}
            <i>{t('settings.configurable')}</i>
          </Typography>
        </Alert>
      </Snackbar>
    </Box>

  );
}
