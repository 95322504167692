import React, { useState } from 'react';
import {
  dynamicUnitName,
} from 'components/units/UnitDefinitionsAndMasks';
import Typography from '@mui/material/Typography';
import ResultCard from 'components/ResultCard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import FirestoreSaveConfirm from 'components/operation/FirestoreSaveConfirm';
import Button from '@mui/material/Button';
import Done from '@mui/icons-material/Done';
import useGlobal from 'global-state/store';
import Alert from '@mui/material/Alert';
import validationSchema from './validationSchemaResults';
import VolumeConversionMethodInfo from './VolumeConversionMethodInfo';

export default function VolumeConversionResults(props) {
  const location = useLocation();
  const [batchResults] = useState(location.state.batchResults);
  const [operation] = useState(location.state.operation);
  const [globalState] = useGlobal();
  const { t } = useTranslation();
  const [saveOpen, setSaveOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [volumeConversionResults, setvolumeConversionResults] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      batchNames: Object.keys(batchResults).map((number) => ({ oldnumber: number, number })),
    },
    validationSchema: validationSchema(t),
    onSubmit(values) {
      setvolumeConversionResults(formatConversionResults(values.batchNames));
      setSaveOpen(true);
    },
  });

  function formatConversionResults(batchNames) {
    const formattedResults = {};
    for (const batch of batchNames) {
      const batchResult = batchResults[batch.oldnumber];
      formattedResults[batch.number] = batchResult;
    }
    return formattedResults;
  }

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 3,
        }}
      >
        {batchResults && Object.entries(batchResults).map((batchResult, index) => (
          <div key={batchResult[0]}>
            <BatchResult
              index={index}
              formik={formik}
              batchNumber={batchResult[0]}
              batchResult={batchResult[1]}
            />
          </div>
        ))}
      </Box>
      <Button
        type="submit"
        size="large"
        disabled={!formik.isValid}
        variant="contained"
        endIcon={<Done />}
      >
        {t('operation.done')}
      </Button>
      <VolumeConversionMethodInfo />
      <FirestoreSaveConfirm
        saveOpen={saveOpen}
        successOpen={successOpen}
        setSaveOpen={setSaveOpen}
        setSuccessOpen={setSuccessOpen}
        forms={operation.forms}
        certificatePath={operation.certificatePath}
        tankNumber={operation.tankNumber}
        transporter={operation.transporter}
        transporterName={operation.transporterName}
        totalVolume={operation.totalVolume}
        volumeConversionResults={volumeConversionResults}
      />
      {globalState.userPlan === 'test_premium'
              && (
              <Alert
                style={{ justifyContent: 'center', alignItems: 'center' }}
                severity="warning"
              >
                {t('comm.test_premium_volume_conversion')}
              </Alert>
              )}
    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}

function BatchResult(props) {
  const {
    batchResult, formik, index,
  } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      borderStyle: 'solid',
      borderWidth: 'medium',
      borderColor: batchResult.color,
      borderRadius: 5,
      p: 2,
    }}
    >
      <Box sx={{
        alignSelf: 'flex-start',
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography component="span" sx={{ fontWeight: 'bold', color: batchResult.color }}>
          {t('volume_conversion.batch_number')}
        </Typography>

        <TextField
          size="small"
          sx={{ width: 100 }}
          id={`batchNames.${index}.number`}
          name={`batchNames.${index}.number`}
          autoComplete="off"
          variant="outlined"
          value={formik.values.batchNames[index].number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.batchNames?.[index]?.number && formik.touched.batchNames?.[index]?.number}
        />
        {formik.errors.batchNames?.[index]?.number
            && formik.touched.batchNames?.[index]?.number && (
            <Typography component="span" variant="caption" sx={{ color: 'red' }}>
              {formik.errors.batchNames?.[index]?.number}
            </Typography>
        ) }
      </Box>
      <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }} component="span" variant="caption">
        {t('operation.compartments')}
        :
        {' '}
        {batchResult.compartmentIds.join(', ')}
        <br />
        {t(
          'volume_conversion.at_temperature',
          {
            temperature: batchResult.requestedValues.temperature,
            temperatureUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
          },
        )}
        <br />
        {t('volume_conversion.tav_result_short', {
          tav: batchResult.requestedValues.tav,
          alcoholUnit: dynamicUnitName(batchResult.requestedValues.alcoholUnit),
          referenceTempValue: batchResult.requestedValues.tavTemperature,
          referenceTempUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
        })}
      </Typography>
      <BatchResultDetail batchResult={batchResult} />
    </Box>
  );
}

function BatchResultDetail(props) {
  const { batchResult } = props;
  const { t } = useTranslation();
  const pureAlcoholResult = batchResult.pureAlcoholVolumeAt20c;
  const volumeResult = batchResult.volumeAtTemp;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        width: '100%',
      }}
    >
      <ResultCard
        title={t('forms.tav_at_ref_alcohol_and_ref_temp', {
          referenceTempValue: batchResult.ABVAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(batchResult.ABVAt20c.temperature.unit),
        })}
        result={batchResult.ABVAt20c.value}
      />

      <ResultCard
        title={t('forms.correction_factor')}
        result={batchResult.volumeCorrectionFactor.value}
      />

      <ResultCard
        title={
    t('forms.volume_at_temp')
    + volumeResult.temperature.value
    + temperatureUnitString(volumeResult.temperature.unit)
  }
        result={`${volumeResult.value} ${dynamicUnitName(volumeResult.unit)}`}
      />

      <ResultCard
        title={t('forms.pure_alcohol', {
          referenceTempValue: pureAlcoholResult.temperature.value,
          referenceTempUnit: dynamicUnitName(
            pureAlcoholResult.temperature.unit,
          ),
        })}
        result={`${pureAlcoholResult.value} ${dynamicUnitName(pureAlcoholResult.unit)}`}
      />
    </Box>
  );
}
