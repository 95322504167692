import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import useGlobal from 'global-state/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

export default function CalculMethod() {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      localStorage.setItem('volume_conversion_method', newAlignment);
      globalActions.setVolumeConversionMethod(newAlignment);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        width: '75%',
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 5,
        gap: 3,
      }}
    >
      <Typography component="span">{t('settings.volume_calculation_method')}</Typography>
      <ToggleButtonGroup
        color="primary"
        value={globalState.volumeConversionMethod}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton value="traditional">{t('settings.traditional_method')}</ToggleButton>
        <ToggleButton value="OIML-OIV">OIML-OIV</ToggleButton>
      </ToggleButtonGroup>
      <Typography component="p" sx={{ whiteSpace: 'pre-line', textAlign: 'left', width: '100%' }}>
        {globalState.volumeConversionMethod === 'traditional'
         && (t('settings.traditional_method_info', { joinArrays: '\n' }))}
        {globalState.volumeConversionMethod !== 'traditional'
         && (t('settings.oiml_oiv_method_info', { joinArrays: '\n' }))}
      </Typography>
      {globalState.volumeConversionMethod !== 'traditional'
         && (<OIMLComplement />)}
    </Paper>
  );
}

function OIMLComplement() {
  const { t } = useTranslation();
  const [displayAll, setdisplayAll] = React.useState(false);
  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <Box>
      {' '}
      <Typography component="span" onClick={() => setdisplayAll(!displayAll)}>
        {t('know_more')}
        <IconButton>
          {expandIcon()}
        </IconButton>
      </Typography>

      <Collapse
        in={displayAll}
        sx={{
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: 1,
          pb: 3,
        }}
        >
          <Typography component="p" sx={{ whiteSpace: 'pre-line', textAlign: 'left', width: '100%' }}>
            {t('settings.oiml_oiv_complement', { joinArrays: '' })}
          </Typography>
        </Box>

      </Collapse>
    </Box>
  );
}
