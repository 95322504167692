const storeIdBase = 'op-compartment-';
const storeOperationIdBase = 'last-operation-';

export function storeSetCptsIds(store, ids) {
  store.setItem(`${storeIdBase}ids`, JSON.stringify(ids));
}

export function storeGetCptsIds(store) {
  const ids = store.getItem(`${storeIdBase}ids`);
  if (ids) {
    return JSON.parse(ids);
  }
  return [];
}

export function storeSetCpts(store, compartments) {
  storeSetCptsIds(
    store,
    compartments.map((c) => c.id),
  );
  for (const c of compartments) {
    storeSetCpt(store, c.id, c.height, c.volume);
  }
}

export function storeSetCpt(store, id, height, volume) {
  store.setItem(
    storeIdBase + id,
    JSON.stringify({
      height,
      volume,
    }),
  );
}

export function storeGetCpt(store, id) {
  let savedCpt = store.getItem(storeIdBase + id);
  if (savedCpt) {
    savedCpt = JSON.parse(savedCpt);
  }
  return savedCpt;
}

export function storeDeleteCpt(store, id) {
  store.removeItem(storeIdBase + id);
}

export function storeSaveOperation(store, id, compartments) {
  store.setItem(storeOperationIdBase + id, JSON.stringify(compartments));
}

export function storeClearOperation(store, id) {
  store.removeItem(storeOperationIdBase + id);
}

export function storeGetOperation(store, id) {
  let savedOperation = store.getItem(storeOperationIdBase + id);
  if (savedOperation) {
    savedOperation = JSON.parse(savedOperation);
  }
  return savedOperation;
}

export function storeClearOperationCpts(store) {
  const compartmentsIds = storeGetCptsIds(store);
  for (const id of compartmentsIds) {
    storeDeleteCpt(store, id);
  }
  store.removeItem(`${storeIdBase}ids`);
}

export function storeOperationIdFromPath(aPath) {
  const pathArray = aPath.split('/');
  return `${pathArray[5]}-${pathArray[7]}`;
}

const storeOperationsArrayKey = 'last-operations-array';

export function getStoredOperationsArray(store) {
  let operationsArray = store.getItem(storeOperationsArrayKey);
  if (operationsArray) {
    operationsArray = JSON.parse(operationsArray);
  } else {
    operationsArray = [];
  }
  return operationsArray;
}

export function storeNewOperationInArray(store, compartments) {
  const operationsArray = getStoredOperationsArray(store);

  operationsArray.unshift(compartments);

  if (operationsArray.length > 10) {
    operationsArray.pop();
  }

  store.setItem(storeOperationsArrayKey, JSON.stringify(operationsArray));
}
