import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Done from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import FirestoreSaveConfirm from './FirestoreSaveConfirm';

export default function OperationButtons(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [globalState] = useGlobal();
  const [saveOpen, setSaveOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const {
    certificatePath, forms, tankNumber, transporter, transporterName, totalVolume, hasErrors, pureAlcoholResults,
  } = props;

  const handleClickSaveOpen = () => {
    if (validate()) {
      setSaveOpen(true);
    }
  };

  function alertWithError(message) {
    setErrorMessage(message);
    setOpenError(true);
  }

  function validate() {
    if (totalVolume <= 0) {
      alertWithError(t('operation.total_volume_zero'));
      return false;
    }
    if (hasErrors) {
      alertWithError(t('operation.has_errors'));
    }
    return !hasErrors;
  }

  function validateNetwork() {
    if (globalState.networkState === 'offline') {
      alertWithError(t('forms.need_network'));
      return false;
    }
    return true;
  }

  const handleGoToVolumeConversion = () => {
    if (validate() && validateNetwork()) {
      sessionStorage.removeItem('selectedBatches');
      navigate('/volumeConversion', {
        state: {
          compartments: forms,
          operation: {
            forms,
            tankNumber,
            certificatePath,
            transporter,
            transporterName,
            totalVolume,
            pureAlcoholResults,
          },
        },
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 3,
      }}
    >
      <Button
        variant="contained"
        size="large"
        onClick={handleClickSaveOpen}
        endIcon={<Done />}
      >
        {t('operation.done')}
      </Button>

      <Button
        disabled={!globalState.volumeConversionEnabled}
        variant="outlined"
        size="large"
        onClick={handleGoToVolumeConversion}
        endIcon={globalState.volumeConversionEnabled ? <ArrowForward /> : <LockIcon />}
      >
        {t('volume_conversion.title')}
      </Button>

      <FirestoreSaveConfirm
        saveOpen={saveOpen}
        successOpen={successOpen}
        setSaveOpen={setSaveOpen}
        setSuccessOpen={setSuccessOpen}
        forms={forms}
        certificatePath={certificatePath}
        tankNumber={tankNumber}
        transporter={transporter}
        transporterName={transporterName}
        totalVolume={totalVolume}
      />
      <Snackbar
        open={openError}
        style={{ marginTop: 200 }}
        autoHideDuration={15000}
        onClose={() => setOpenError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert style={{ padding: 50 }} severity="warning">
          <AlertTitle>{t('operation.cant_save')}</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
