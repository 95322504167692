import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import TransporterSelect from './TransporterSelect';

export default function TankFilters({
  formik, datePickersLabel = '',
  selectedTransporterCallback = () => {},
  defaultTransporter = false,
}) {
  const { t } = useTranslation();

  const handleStartDateChange = (newValue) => {
    const newDate = newValue.toDate();
    if (newDate !== formik.values.startDate) {
      if (newDate > formik.values.endDate) {
        const newEndDate = new Date(newDate);
        newEndDate.setHours(23, 59, 0, 0); // next before midnight
        formik.setFieldValue('endDate', newEndDate);
      }
      formik.setFieldValue('startDate', newDate);
    }
  };

  const handleEndDateChange = (newValue) => {
    const newDate = newValue.toDate();
    if (newDate !== formik.values.endDate) {
      if (newDate < formik.values.startDate) {
        const newStartDate = new Date(newDate);
        newStartDate.setHours(0, 0, 0, 0); // last midnight
        formik.setFieldValue('startDate', newStartDate);
      }
      formik.setFieldValue('endDate', newDate);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        width: '100%',
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <TransporterSelect
        defaultTransporter={defaultTransporter}
        isTransporterValid={formik.isValid}
        selectCallback={(transporter) => {
          formik.setFieldValue('transporterName', transporter);
          selectedTransporterCallback(transporter);
        }}
      />
      <Typography variant="body2">
        {datePickersLabel}
        {' '}
        :
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          label={t('admin.between_start_date')}
          inputFormat="DD/MM/YYYY"
          value={formik.values.startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{ backgroundColor: 'white' }}
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          label={t('admin.between_end_date')}
          value={formik.values.endDate}
          inputFormat="DD/MM/YYYY"
          onChange={handleEndDateChange}
          renderInput={(params) => (
            <TextField
              size="small"
              sx={{ backgroundColor: 'white' }}
                // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <Button
        disabled={!formik.isValid}
        type="submit"
        size="small"
        variant="contained"
        color="primary"
      >
        {t('apply_filters')}
      </Button>
    </Paper>
  );
}
