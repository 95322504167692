/* eslint-disable react/destructuring-assignment */
// TODO make props more explicit
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NumberInput } from 'components/Inputs';

export default function CompartmentField(props) {
  const handleBlur = (e) => {
    props.setUpdate(e.target.value, props.name);
  };

  const handleKeyPressed = (e) => {
    const key = e.key ? e.key : e.code;
    if (['Enter', 'NumpadEnter'].includes(key)) {
      handleBlur(e);
    }
  };

  const handleChange = (e) => {
    // To update on each character change
  };

  return (
    <Box sx={{ ...props.inputStyle }}>
      <NumberInput
        id={props.name}
        inputMode={props.inputMode}
        name={props.name}
        decimalScale={props.decimalScale}
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPressed}
        highlighted={+props.highlighted}
      />
      <Typography
        variant="caption"
        sx={{ color: '#e21f1f' }}
        display="block"
        gutterBottom
      >
        {props.error}
      </Typography>
    </Box>
  );
}
