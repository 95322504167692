import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { nominalVolumeFromCompartment } from '../../utils';

export default function OperationDetails(props) {
  const { operation, organizationName } = props;
  const { t } = useTranslation();

  const date = new Date((operation.dateAndTime.seconds * 1000) + (operation.dateAndTime.nanoseconds / 1000000));

  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', gap: 1, width: '100%',
      }}
    >
      <Typography variant="body2">
        {`${t('tank_selection.transporter')} : ${operation.transporterName} (${organizationName})`}
        <br />
        {`${t('tank_selection.certificate')} : ${operation.certificateNumber}`}
        <br />
        <br />
        {t('tank_selection.operation_date')}
        :
        {' '}
        {date.toLocaleString()}
        <br />
        ID operation:
        {' '}
        {operation.id}
        <br />
        {t('admin.operator')}
        :
        {operation.userEmail}
      </Typography>
      {operation.compartmentsHeightsAndVolumes.map((compartment, i) => (
        <div key={compartment.number}>
          <CompartmentOperation compartment={compartment} />
        </div>
      ))}
      {t('operation.total_volume')}
      {`: ${operation.totalVolume}`}
    </Box>
  );
}

function CompartmentOperation(props) {
  const { t } = useTranslation();
  const { compartment } = props;

  return (
    <Box
      sx={{
        display: 'flex', gap: 0, flexDirection: 'column', width: '100%',
      }}
    >
      <Box sx={{
        display: 'flex', gap: 0, flexDirection: 'row',
      }}
      >
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          component="div"
        >
          <b>
            {t('operation.compartment')}
            :
            {compartment.number}
          </b>
        </Typography>
        <Typography
          sx={{ width: '50%' }}
          variant="subtitle2"
          component="div"
        >
          {t('operation.nominal_volume')}
          :
          {nominalVolumeFromCompartment(compartment, t)}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', width: '100%', gap: 1,
      }}
      >
        <Box sx={{ width: '50%' }}>
          <Typography variant="body2">
            {`${t('operation.height')} : ${compartment.height}`}
          </Typography>
        </Box>
        <Box sx={{ width: '50%' }}>
          <Typography variant="body2">
            {`${t('operation.volume')} : ${compartment.volume}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
