import React from 'react';
import {
  dynamicUnitName,
} from 'components/units/UnitDefinitionsAndMasks';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

export default function OperationVolumeConversion(props) {
  const { batchResults } = props;
  const { t } = useTranslation();
  const [displayAll, setdisplayAll] = React.useState(false);

  const expandIcon = () => (displayAll ? <ExpandLessIcon /> : <ExpandMoreIcon />);

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        display: 'flex',
        gap: 2,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        onClick={() => setdisplayAll(!displayAll)}
      >
        <Typography>{t('volume_conversion.results')}</Typography>
        <IconButton>
          {expandIcon()}
        </IconButton>
      </Box>
      <Collapse
        in={displayAll}
        sx={{
          width: '100%',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          gap: 1,
          pb: 3,
        }}
        >
          {batchResults && Object.entries(batchResults).map((batchResult, index) => (
            <div key={batchResult[0]}>
              <BatchResult
                batchNumber={batchResult[0]}
                batchResult={batchResult[1]}
              />
            </div>
          ))}
        </Box>

      </Collapse>

    </Box>
  );
}

function temperatureUnitString(temperatureUnit) {
  if (temperatureUnit === 'celsius') {
    return ' °C';
  }
  return ' °F';
}

function BatchResult(props) {
  const { batchResult, batchNumber } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{
      display: 'flex',
      gap: 1,
      flexDirection: 'column',
      border: 1,
      borderRadius: 1,
      p: 2,
    }}
    >
      <Box sx={{
        alignSelf: 'flex-start',
        display: 'flex',
        gap: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography component="span" sx={{ fontWeight: 'bold', color: batchResult.color }}>
          {t('volume_conversion.batch_number')}
          {batchNumber}
        </Typography>
      </Box>
      <Typography sx={{ alignSelf: 'flex-start', textAlign: 'left' }} component="span" variant="caption">
        {t('operation.compartments')}
        {': '}
        {batchResult.compartmentIds.join(', ')}
        <br />
        {t(
          'volume_conversion.at_temperature',
          {
            temperature: batchResult.requestedValues.temperature,
            temperatureUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
          },
        )}
        <br />
        {t('volume_conversion.tav_result_short', {
          tav: batchResult.requestedValues.tav,
          alcoholUnit: dynamicUnitName(batchResult.requestedValues.alcoholUnit),
          referenceTempValue: batchResult.requestedValues.tavTemperature,
          referenceTempUnit: dynamicUnitName(batchResult.requestedValues.temperatureUnit),
        })}
      </Typography>
      <BatchResultDetail batchResult={batchResult} />
    </Box>
  );
}

function BatchResultDetail(props) {
  const { batchResult } = props;
  const { t } = useTranslation();
  const oldFormat = batchResult.convertedVolume !== undefined;

  let volumeAtTemp;
  let pureAlcoholVolumeAt20c;
  let volumeCorrectionFactor;

  if (oldFormat) {
    volumeAtTemp = {
      value: batchResult.convertedVolume.value,
      unit: batchResult.requestedValues.volumeUnit,
      temperature: {
        value: batchResult.convertedVolume.temperatureValue,
        unit: batchResult.convertedVolume.temperatureUnit,
      },
    };
    const pureAlcoholResult = batchResult.pureAlcoholVolumeAtReferenceTemperature;
    pureAlcoholVolumeAt20c = {
      value: pureAlcoholResult.value,
      unit: batchResult.requestedValues.volumeUnit,
      temperature: {
        value: pureAlcoholResult.temperatureValue,
        unit: pureAlcoholResult.temperatureUnit,
      },
    };
    volumeCorrectionFactor = {
      value: batchResult.conversionCoefficient.value,
    };
  } else {
    volumeAtTemp = batchResult.volumeAtTemp;
    volumeCorrectionFactor = batchResult.volumeCorrectionFactor;
    pureAlcoholVolumeAt20c = batchResult.pureAlcoholVolumeAt20c;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 1,
      }}
    >
      {batchResult.ABVAt20c && (
        <Typography component="span">
          {t('forms.tav_at_ref_alcohol_and_ref_temp', {
            referenceTempValue: batchResult.ABVAt20c.temperature.value,
            referenceTempUnit: dynamicUnitName(batchResult.ABVAt20c.temperature.unit),
          })}
          {': '}
          {`${batchResult.ABVAt20c.value}`}
        </Typography>
      )}
      <Typography component="span">
        {t('forms.correction_factor')}
        {': '}
        {volumeCorrectionFactor.value}
      </Typography>
      <Typography component="span">
        {t('forms.volume_at_temp')
    + volumeAtTemp.temperature.value
    + temperatureUnitString(volumeAtTemp.temperature.unit)}
        {': '}
        {`${volumeAtTemp.value} ${dynamicUnitName(volumeAtTemp.unit)}`}
      </Typography>
      <Typography component="span">
        {t('forms.pure_alcohol', {
          referenceTempValue: pureAlcoholVolumeAt20c.temperature.value,
          referenceTempUnit: dynamicUnitName(
            pureAlcoholVolumeAt20c.temperature.unit,
          ),
        })}
        {': '}
        {`${pureAlcoholVolumeAt20c.value} ${dynamicUnitName(pureAlcoholVolumeAt20c.unit)}`}
      </Typography>

    </Box>
  );
}
